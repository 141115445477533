import { useEffect, useState } from "react";
import "@styles/common.scss";
import { AuthProvider } from "@providers/AuthContext";
import AccountCreationView from "./onboarding/AccountCreationView";
import LoginView from "./onboarding/LoginView";
import MagicLinkView from "./onboarding/MagicLinkView";
import ProtectedRoute from "@routes/ProtectedRoute";
import DeepLinkRoute from "@routes/DeepLinkRoute";
import ScrollToTop from "@utils/ScrollToTop";
import { LayoutProvider } from "@providers/LayoutContext";
import { Toaster } from "react-hot-toast";
import HomeLayout from "@layouts/HomeLayout";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import UTMCollector from "@utils/UTMCollector";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import NotFound from "./common/NotFound";
import HomeRoute from "@routes/HomeRoute";
import EarnDashboard from "./earn/EarnDashboard";
import GrowDashboard from "./grow/GrowDashboard";
import EarnRoute from "@routes/EarnRoute";
import GrowRoute from "@routes/GrowRoute";
import GrowJoin from "./grow/GrowJoin";
import EarnJoin from "./earn/EarnJoin";
import EarnPayouts from "./earn/payouts/EarnPayouts";
import GrowAlerts from "./grow/alerts/GrowAlerts";
import GrowIntegrations from "./grow/integrations/GrowIntegrations";
import GrowSubscribers from "./grow/subscribers/GrowSubscribers";

const App = () => {
	useEffect(() => {
		recordEvent(kAnalyticsConstants.App.sessionStarted);
	}, []);

	return (
		<Router>
			<Toaster />
			<UTMCollector />
			<ScrollToTop />
			<Routes>
				<Route path="*" element={<NotFound />} />
				<Route element={<AuthProvider />}>
					<Route
						path="/"
						element={
							<>
								<ProtectedRoute />
							</>
						}>
						<Route element={<LayoutProvider />}>
							<Route element={<HomeLayout />}>
								<Route path="/" element={ <HomeRoute /> } />
								<Route path="/earn/join" element={ <EarnJoin /> } />
								<Route element={ <EarnRoute />} >
									<Route path="/earn" element={ <EarnDashboard /> } />
									<Route path="/earn/payouts" element={ <EarnPayouts /> } />
								</Route>
								<Route path="/grow/join" element={ <GrowJoin /> } />
								<Route element={ <GrowRoute />} >
									<Route path="/grow" element={ <GrowDashboard /> } />
									<Route path="/grow/subscribers" element={ <GrowSubscribers /> } /> 
									<Route path="/grow/alerts" element={ <GrowAlerts /> } />
									<Route path="/grow/integrations" element={ <GrowIntegrations /> } />
								</Route>
							</Route>
						</Route>
					</Route>
					<Route path="/magic-link" element={<MagicLinkView />} />
					<Route element={<DeepLinkRoute />}>
						<Route path="/auth/:id" element={<MagicLinkView />} />
					</Route>
				</Route>
				<Route path="/get-started" element={<AccountCreationView />} />
				<Route path="/login" element={<LoginView />} />
			</Routes>
		</Router>
	);
};

export default App;
