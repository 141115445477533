import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";
import LoadingView from "@components/common/LoadingView";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";

const ProtectedRoute = () => {
	const { authUser } = useAuth();
	
	if (authUser === undefined) {
		return <LoadingView loadingState={{ isLoading: true }} />;
	}

	if (!authUser) {
		let nextPath = window.location.pathname;
		if (nextPath) {
			let searchParams = window.location.search ?? "";
			sessionStorage.setItem(kLocalStorageKeys.Session.nextPath, `${nextPath}${searchParams}`);
		}
		return <Navigate to={{ pathname: "/login" }} />;
	}

	return <Outlet />;
};

export default ProtectedRoute;