import { useAuth } from "@providers/AuthContext";
import TitleHeader from "@components/common/TitleHeader";
import { useEffect, useState } from "react";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import SectionLayout from "@layouts/SectionLayout";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Icon } from "@tremor/react";
import { PUBIntegrationsResponse } from "@models/Models";
import { generateToast } from "@utils/managers/ToastManager";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import GrowIntegrationsModal from "./GrowIntegrationsModal";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const GrowIntegrations = () => {
	const { activeNewsletter } = useAuth();
	const [integrationsResponse, setIntegrationsResponse] = useState<PUBIntegrationsResponse | undefined>(undefined);
	const [isShowIntegrationsModal, setShowIntegrationsModal] = useState<boolean>(false);

	useEffect(() => {
		recordEvent(kAnalyticsConstants.Grow.integrationsViewed);
		getIntegrations();
	}, []);

	const getIntegrations = () => {
		setIntegrationsResponse(undefined);
		networkManager
			.getIntegrations(activeNewsletter!.id.toString())
			.then((integrations) => {
				setIntegrationsResponse(integrations);
			})
			.catch((_error) => {
				setIntegrationsResponse(undefined);
				generateToast({ status: "error", title: "Something went wrong.", message: "Please try again later or contact us." });
			});
	};

	function renderAppropriateButton() {
		if (!integrationsResponse) {
			return (
				<button className="w-[154px] flex flex-row  justify-center text-success-green font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center border-solid border-[1px] border-success-green whitespace-nowrap cursor-not-allowed" onClick={() => null}>
					<LoadingSpinner className="h-[20px] text-success-green/20 animate-spin fill-success-green" />
				</button>
			);
		}
		if (integrationsResponse!.newsletter_integration?.integration_id) {
			//view integration
			return (
				<button className="text-success-green font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center border-solid border-[1px] border-success-green whitespace-nowrap" onClick={() => setShowIntegrationsModal(true)}>
					View integration
				</button>
			);
		} else {
			//set up integration
			return (
				<button className="text-white font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center bg-success-green whitespace-nowrap" onClick={() => setShowIntegrationsModal(true)}>
					Set up an integration
				</button>
			);
		}
	}

	return (
		<>
			<GrowIntegrationsModal isShow={isShowIntegrationsModal} onClose={() => setShowIntegrationsModal(false)} integrationsResponse={integrationsResponse} setIntegrationResponse={setIntegrationsResponse} />
			<TitleHeader title="Grow with Meco" />
			<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12 min-h-[100vh]">
				<div className="flex flex-col gap-5 m-auto">
					<SectionLayout sectionTitle="Integrations">
						{integrationsResponse && integrationsResponse.newsletter_integration?.error && (
							<div className="w-full border-[1px] bg-red-600 border-card rounded-[10px] p-[20px] font-regular font-primary text-base text-white flex flex-row items-center gap-3">
								<Icon icon={ExclamationTriangleIcon} color="amber" variant="light" size="sm" />
								<div>[Important] There is an issue with your integration which may prevent new subscribers being added directly to your newsletter list. Please reconfigure your integration.</div>
							</div>
						)}
						<div className="w-full border-[1px] border-primary-100 rounded-[10px] p-[20px] bg-surface-200">
							<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-between">
								<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center">
									<div className="text-primary font-medium font-primary text-lg">Automatically add new subscribers to your newsletter list</div>
									<div className="text-primary-500 font-regular font-primary text-sm">We integrate with a number of newsletter publishing platforms so you can get new subscribers from Meco sent directly to your newsletter list.</div>
								</div>
								<div className="flex flex-col gap-3 items-center w-full sm:w-auto">{renderAppropriateButton()}</div>
							</div>
						</div>
					</SectionLayout>
				</div>
			</div>
		</>
	);
};

export default GrowIntegrations;
