import axios from "axios";
import * as networkConfig from "./NetworkConfig";
import * as Sentry from "@sentry/react";
import { PUBProfile, PUBReferralDetail, PUBBReferralOverviewStatsResponse, PUBGrowDetail, PUBBAffiliateOverviewStatsResponse, PUBIntegrationsResponse, PUBSubscriberListResponse, PUBSubscriberExportInfo } from "../../../models/Models";
import { kAppConstants } from "@utils/constants/AppConstants";
import moment from "moment";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";

function headers() {
	let header = { "Content-Type": "application/json" };
	return header;
}

function paramsAppended(url: string) {
	if (url.includes("?")) {
		return url + "&platform=web";
	}

	return url + "?platform=web";
}

const api = axios.create({
	withCredentials: true,
	baseURL: networkConfig.baseURL(),
});

api.interceptors.request.use((request) => {
	if (kAppConstants.appStage !== "production") {
		console.log("Starting Request", JSON.stringify(request, null, 2));
	}
	return request;
});

api.interceptors.response.use((response) => {
	if (kAppConstants.appStage !== "production") {
		console.log("Response:", JSON.stringify(response, null, 2));
	}
	return response;
});

function isUserAuthenticated(error: any) {
	if ((error.response?.status ?? 0) === 403) {
		window.location.href = "/login?expired=true";
	}
}

export function signUpUser(signUpUserModel: Record<string, string>) {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.signUpUser), signUpUserModel, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				var internalError = error;
				if (error.response.status == 409) {
					internalError = kErrorConstants.signUp.existingAccount;
				}
				reject(internalError);
			});
	});
}

export function logInUser(emailAddress) {
	return new Promise((resolve, reject) => {
		let data = { email: emailAddress };

		api
			.post(paramsAppended(networkConfig.Endpoints.logInUser), data, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				var internalError = error;
				if (error.response.status == 404) {
					internalError = kErrorConstants.logIn.nonExistantAccount;
				}
				reject(internalError);
			});
	});
}

export function validateMagicLinkToken(magicLinktoken): Promise<void> {
	return new Promise((resolve, reject) => {
		let data = { magic_link_token: magicLinktoken };

		api
			.post(paramsAppended(networkConfig.Endpoints.validateMagicLink), data, { headers: headers() })
			.then((_response) => {
				sessionStorage.removeItem(kLocalStorageKeys.Session.utmCampaign);
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function validateMagicCode(code): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		let data = { magic_link_code: code };
		const isSkipIntegrations = kAppConstants.appStage !== 'production' ? "&skip_integrations=true" : "";
		api
			.post(paramsAppended(networkConfig.Endpoints.validateMagicCode) + isSkipIntegrations, data, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function logOutUser(): Promise<void> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.logOutUser), { headers: headers() })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getUserProfile(logOutUserIfUnauthorized: boolean = true): Promise<PUBProfile> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.userProfile), { headers: headers() })
			.then((response) => {
				try {
					const userProfile = new PUBProfile(response["data"]);
					Sentry.setUser({ id: userProfile.id, email: userProfile.email });
					resolve(userProfile);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				if (logOutUserIfUnauthorized) {
					isUserAuthenticated(error);
				}
				reject(error);
			});
	});
}

export function getReferralLink(selectedNewsletterId: string): Promise<PUBReferralDetail> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.getReferralLink + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const referralDetail = new PUBReferralDetail(data);
				resolve(referralDetail);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getReferralOverviewStats(selectedNewsletterId: string, selectedDate: Record<string, any>): Promise<PUBBReferralOverviewStatsResponse> {
	return new Promise((resolve, reject) => {
		var dateQuery = "";

		if (selectedDate.month && selectedDate.year) {
			const correctMonthFormat = selectedDate.month < 10 ? `0${selectedDate.month}` : selectedDate.month;
			dateQuery = "?date=" + selectedDate.year + "-" + correctMonthFormat;
		}

		var endpointUrl = networkConfig.Endpoints.getReferralOverviewStats + selectedNewsletterId + dateQuery;

		api
			.get(endpointUrl, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				var overviewStatsResponse = new PUBBReferralOverviewStatsResponse(data);
				resolve(overviewStatsResponse);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postWiseData(selectedNewsletterId: string, email: string, currency: string, accountType: string): Promise<void> {
	return new Promise((resolve, reject) => {
		let data = { email_address: email, currency: currency, account_type: accountType.toUpperCase() };

		api
			.post(networkConfig.Endpoints.postWiseData + selectedNewsletterId, data, { headers: headers() })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function createReferralLink(selectedNewsletterId: string, audienceProperties: Record<string, string>): Promise<PUBReferralDetail> {
	return new Promise((resolve, reject) => {
		api
			.post(networkConfig.Endpoints.createReferralLink + selectedNewsletterId, audienceProperties, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const referralDetail = new PUBReferralDetail(data);
				resolve(referralDetail);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getReferralPayouts(selectedNewsletterId: string): Promise<[PUBBReferralOverviewStatsResponse]> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.getReferralHistory + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"] && response["data"]["payout_history"];
				var overviewStatsResponseArray = data.map((overviewStatResponse: PUBBReferralOverviewStatsResponse) => new PUBBReferralOverviewStatsResponse(overviewStatResponse));
				resolve(overviewStatsResponseArray);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getGrowDetail(selectedNewsletterId: string): Promise<PUBGrowDetail> {
	return new Promise((resolve, reject) => {
		const isIgnoreCC = kAppConstants.appStage !== 'production' ? "?ignore_cc=true" : "";
		api
			.get(networkConfig.Endpoints.getAffiliateDetail + selectedNewsletterId + isIgnoreCC, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const growDetail = new PUBGrowDetail(data);
				resolve(growDetail);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getAffiliateOverviewStats(campaignId: string, fromDate: Record<string, any>, toDate: Record<string, any>): Promise<PUBBAffiliateOverviewStatsResponse> {
	return new Promise((resolve, reject) => {
		var dateQuery = "";

		const fromDateString = moment(fromDate).format("YYYY-MM-DD");
		const toDateString = moment(toDate).format("YYYY-MM-DD");
		dateQuery = "?start_date=" + fromDateString + "&end_date=" + toDateString;

		var endpointUrl = networkConfig.Endpoints.getAffiliateOverviewStats + campaignId + dateQuery;

		api
			.get(endpointUrl, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				var overviewStatsResponse = new PUBBAffiliateOverviewStatsResponse(data);
				resolve(overviewStatsResponse);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postAlerts(payload) {
	let utcDeliveryTime = moment("07:00", "HH:mm").utc();
	let convertedDeliveryTime = moment(utcDeliveryTime).format("HH:mm");
	var deliveryTime = 7;
	if (convertedDeliveryTime) {
		deliveryTime = parseInt(convertedDeliveryTime.slice(0, 2));
	}
	payload["local_delivery_time"] = deliveryTime;

	return new Promise(function (resolve, reject) {
		api
			.post(networkConfig.Endpoints.alerts, payload, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getIntegrations(selectedNewsletterId): Promise<PUBIntegrationsResponse> {
	return new Promise(function (resolve, reject) {
		api
			.get(networkConfig.Endpoints.integrations + "/" + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const integrationsResponse = new PUBIntegrationsResponse(data);
				resolve(integrationsResponse);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function deleteIntegration(selectedNewsletterId): Promise<PUBIntegrationsResponse> {
	return new Promise(function (resolve, reject) {
		api
			.delete(networkConfig.Endpoints.integrations + "/" + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postIntegration(selectedNewsletterId, payload): Promise<PUBIntegrationsResponse> {
	return new Promise(function (resolve, reject) {
		api
			.post(networkConfig.Endpoints.integrations + "/" + selectedNewsletterId, payload, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getSubscriberList(selectedNewsletterId, page): Promise<PUBSubscriberListResponse> {
	return new Promise(function (resolve, reject) {
		api
			.get(networkConfig.Endpoints.subscriberList + "/" + selectedNewsletterId + "?page_size=20" + "&page=" + page, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const integrationsResponse = new PUBSubscriberListResponse(data);
				resolve(integrationsResponse);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function subscriberExportInfo(selectedNewsletterId): Promise<PUBSubscriberExportInfo> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.subscriberExportInfo + "/" + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const exportInfoResponse = new PUBSubscriberExportInfo(data);
				resolve(exportInfoResponse);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function subscriberExport(selectedNewsletterId: string, sinceLastExport: boolean): Promise<void> {
	return new Promise((resolve, reject) => {
		let lastExportBoolString = sinceLastExport ? "?since_last_export=true" : "";

		api
			.get(`${networkConfig.Endpoints.subscriberExport}/${selectedNewsletterId}${lastExportBoolString}`, {
				headers: headers(),
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data], { type: "text/csv" });
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);

				let today = new Date();
				let dd = String(today.getDate()).padStart(2, "0");
				let mm = String(today.getMonth() + 1).padStart(2, "0");
				let yyyy = today.getFullYear();
				let fileName = "Meco_subscriber_export_" + dd + "_" + mm + "_" + yyyy;
				link.download = `${fileName}.csv`
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}
