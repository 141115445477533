import { Navigate } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";

const HomeRoute = ({...rest}) => {
	const { growActive } = useAuth();

    if (growActive === undefined) {
		return;
	}

    if (growActive) {
        return <Navigate to="/grow" />;
    }

    return <Navigate to="/earn" />;

};

export default HomeRoute;
