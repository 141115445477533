import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { PUBBReferralOverviewStatsResponse } from "@models/Models";
import { useAuth } from "@providers/AuthContext";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { generateToast } from "@utils/managers/ToastManager";
import { useEffect, useState } from "react";
import SectionLayout from "@layouts/SectionLayout";
import moment from "moment";
import { Badge, Card, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Text } from "@tremor/react";
import TitleHeader from "@components/common/TitleHeader";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const EarnPayouts = () => {
	const { activeNewsletter } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [overviewStatResponseArray, setOverviewStatResponseArray] = useState<[PUBBReferralOverviewStatsResponse] | null>(null);

	useEffect(() => {
		recordEvent(kAnalyticsConstants.Earn.earnPayoutsClicked);	
		getData();
	}, []);

	interface RowObject {
		date?: string;
		earnings?: string | null;
		price?: string | null;
		referrals?: string | null;
		earnings_status?: string | null;
	}

	const headerDictionary: RowObject = {
		date: "Date",
		earnings: "Earnings",
		price: "Avg. Earnings per Referral",
		referrals: "Referrals",
		earnings_status: "Payment Status",
	};

	const getData = () => {
		setIsLoading(true);
		networkManager
			.getReferralPayouts(activeNewsletter!.id.toString())
			.then((overviewResponseArray) => {
				setIsLoading(false);
				setOverviewStatResponseArray(overviewResponseArray);
			})
			.catch((error) => {
				setIsLoading(false);
				if (error.response.status !== 404) {
					generateToast({ status: "error", title: "Something went wrong.", message: "We couldn't get your payouts. Please try again later." });
				}
			});
	};

	const getStatusColor = (status?: string | null) => {
		switch (status) {
			case "pending":
				return "amber";
			case "paid":
				return "green";
			case "failed":
				return "red";
			default:
				return "transparent";
		}
	};

	const getDateString = (date?: string | null) => {
		const inputDateString = date;
		const inputMoment = moment(inputDateString, "YYYY-MM");
		const outputString = inputMoment.format("MMMM YYYY");
		return outputString;
	};

	const generateRows = (responseArray: PUBBReferralOverviewStatsResponse[]): RowObject[] => {
		const rowDict: RowObject[] = [];

		responseArray.forEach((response) => {
			const dict: RowObject = {
				date: getDateString(response.date),
				earnings: null,
				price: null,
				referrals: null,
				earnings_status: null,
			};

			Object.keys(headerDictionary).forEach((key: string) => {
				response.overview_stats.forEach((metric) => {
					if (key === metric.id) {
						dict[key] = metric.value;
					}
					if (key === "earnings_status" && metric.id === "earnings") {
						dict[key] = metric.status;
					}
				});
			});

			const dictContainsAllKeys = Object.keys(headerDictionary).every((key: string) => {
				return key in dict;
			});

			if (dictContainsAllKeys) {
				rowDict.push(dict);
			}
		});

		return rowDict;
	};

	return (
		<>
			{isLoading ? (
				<div className="w-full h-[100vh] flex items-center justify-center">
					<LoadingSpinner className="w-[40px] text-primary-200 animate-spin fill-primary" />
				</div>
			) : (
				<>
					<TitleHeader title="Earn with Meco" />
					<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12 min-h-[100vh]">
						<div className="flex flex-col gap-5 m-auto">
							<SectionLayout id="stats" sectionTitle="Your Payouts">
								<Table>
									<TableHead>
										<TableRow>
											{Object.keys(headerDictionary).map((key: string) => (
												<TableHeaderCell key={key} className="text-center">
													{headerDictionary[key]}
												</TableHeaderCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{overviewStatResponseArray &&
											generateRows(overviewStatResponseArray) &&
											generateRows(overviewStatResponseArray).map((dict) => (
												<TableRow key={dict["date"]}>
													{Object.keys(dict).map((row) => (
														<TableCell className="text-center">{row == "earnings_status" ? <Badge color={getStatusColor(dict[row])}>{dict[row]?.toUpperCase() ?? "-"}</Badge> : <Text>{dict[row]}</Text>}</TableCell>
													))}
												</TableRow>
											))}
									</TableBody>
								</Table>
							</SectionLayout>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default EarnPayouts;
