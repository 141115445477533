import { LucideProps } from "lucide-react";

interface MetricIconProps {
    Icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref">>;
    size?: number;
    iconColor?: "yellow";
    isFillColor?: boolean;
}

const MetricIcon: React.FC<MetricIconProps> = ({ Icon, size, iconColor, isFillColor }) => {

    const getColor = () => {
        switch (iconColor) {
            // case "yellow":
            //     return "bg-yellow-500";
            default:
                return "bg-green-500";
        }
    }

    const getIconColor = () => {
        switch (iconColor) {
            case "yellow":
                return "#ecc94b";
            default:
                return "#07ab35";
        }
    }

	return (
		<div className={`${getColor()} bg-opacity-20 p-[6px] rounded-lg inline-block`}>  
            <Icon size={size ? size : 20} color={getIconColor()} fill={isFillColor ? getIconColor() : "none"}/>
        </div>
	);
};

export default MetricIcon;
