import { useLayout } from "@providers/LayoutContext";
import DefaultSidebar from "./DefaultSidebar";
import { LucideProps } from "lucide-react";
import SettingsSidebar from "./SettingsSidebar";
import { useEffect, useState } from "react";

export interface MenuItem {
	icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref">>;
	title: string;
	subMenu?: SubMenuItem[] | undefined;
	subMenuExpandState?: boolean;
	subMenuLoadingState?: boolean;
	link: LinkItem;
}

export interface SubMenuItem {
	key: string;
	title: string;
	action: () => void;
	isActive: boolean;
}

export interface LinkItem {
	link: string;
	onAction?: () => void;
	isParentLink?: boolean | undefined;
}

const SidebarWrapper = () => {
	const { isSidebarHidden, wallpaper, isSettingsActive, sideBarWidth } = useLayout();
	const [responsiveSidebarWidth, setResponsiveSidebarWidth] = useState<number>(sideBarWidth);
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 640) {
				setResponsiveSidebarWidth(sideBarWidth); // Show sidebar when the window is larger than 640px
			} else if (window.innerWidth <= 640) {
				setResponsiveSidebarWidth(window.innerWidth); // Hide sidebar when the window is smaller than or equal to 640px
			}
		};

		window.addEventListener("resize", handleResize);
		handleResize();
	}, []);

	useEffect(() => {
		const section = document.getElementById("sidebar_wrapper");
		if (section) {
			section.scrollTo({
				left: isSettingsActive ? responsiveSidebarWidth : 0,
			});
		}
	}, [responsiveSidebarWidth]);

	useEffect(() => {
		const section = document.getElementById("sidebar_wrapper");
		if (section) {
			section.scrollTo({
				left: isSettingsActive ? responsiveSidebarWidth : 0,
				behavior: "smooth",
			});
		}
	}, [isSettingsActive]);

	return (
		<>
			<div id="sidebar_wrapper" className={`${isSidebarHidden ? "hidden" : "block"} sm:w-[300px] w-full h-screen fixed border-r border-primary-100 z-30 transition-[width] ease-in-out duration-300 overflow-hidden flex flex-row bg-white-overlay ${wallpaper.sidebarColor} bg-secondary scrollbar scrollbar-thumb-surface scrollbar-track-transparent`}>
				<DefaultSidebar />
				<SettingsSidebar />
			</div>
		</>
	);
};

export default SidebarWrapper;
