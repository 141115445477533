import { useLayout } from "@providers/LayoutContext";
import { ReactComponent as BackIcon } from "@images/back_icon.svg";
import IconButton from "../IconButton";
import { useNavigate } from "react-router";
import { MenuItem } from "./SidebarWrapper";
import * as userManager from "@utils/managers/UserManager";
import Alert, { AlertState } from "../Alert";
import { useState } from "react";
import { ReactComponent as LogOutIcon } from "@images/logout_icon.svg";
import { ReactComponent as HelpIcon } from "@images/help_icon.svg";
import { ReactComponent as DarkModeIcon } from "@images/dark_mode_icon.svg";
import { useAuth } from "@providers/AuthContext";
import { ArrowLeftRight } from "lucide-react";
import { ThemeState } from "@models/Enums";
import { Switch } from "@headlessui/react";

const SettingsSidebar = () => {
	const { authUser, activeNewsletter } = useAuth();
	const { navigationBarHeight, setSettingsActive, theme, setTheme } = useLayout();
	const navigate = useNavigate();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const isDarkMode = theme === ThemeState.dark;

	const mecoProTitle = "Meco PRO";
	const connectedMailboxesTitle = "Connected Mailboxes";
	const groupsTitle = "Groups";
	const giveFeedbackTitle = "Give Feedback";
	const switchToReaderTitle = "Switch to Meco Reader";
	const helpSupportTitle = "Get Support";
	const logOutTitle = "Log Out";

	const switchToReader: MenuItem = { icon: ArrowLeftRight, title: switchToReaderTitle, link: { link: "" } };
	const supportItem: MenuItem = { icon: HelpIcon, title: helpSupportTitle, link: { link: "" } };
	const logOutItem: MenuItem = { icon: LogOutIcon, title: logOutTitle, link: { link: "" } };

	const menuItems: MenuItem[] = [switchToReader, supportItem, logOutItem];

	const buttonOnClick = (title: string) => {
		var sectionId: string | undefined;

		switch (title) {
			case mecoProTitle:
				sectionId = "meco_pro";
				break;
			case connectedMailboxesTitle:
				sectionId = "connected_mailboxes";
				break;
			case groupsTitle:
				sectionId = "groups";
				break;
			case giveFeedbackTitle:
				sectionId = "feedback";
				break;
			case switchToReaderTitle:
				window.location.replace("https://web.meco.app");
				break;
			case helpSupportTitle:
				const emailTo = "team@meco.app";
				const userId = authUser!.id;
				const newsletterName = activeNewsletter!.name;
				const emailBody = `*** Publisher:${userId}:${newsletterName} Type your email below ***`;
				window.open(`mailto:${emailTo}?subject=Publisher support request&body=${emailBody}`, "_self");
				break;
			case logOutTitle:
				setAlertState({ isShow: true, title: "Are you sure?", message: "You will be logged out from your account", actionButton: "Log Out", dismissButton: "Cancel", actionButtonAction: () => logOut() });
				break;
		}

		if (!sectionId) return;
		const section = document.getElementById(sectionId);
		if (section) {
			section.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	const logOut = () => {
		userManager.logOutUser(true, () => {
			navigate("/login");
		});
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<div className="flex flex-col items-center flex-shrink-0 flex-grow-0 overflow-y-auto basis-full sm:basis-[300px]">
				<div className="sticky top-0 bg-contrast/5 backdrop-blur-lg flex flex-row justify-start gap-2 w-full items-center p-3 border-b border-primary-100" style={{ height: navigationBarHeight + "px" }}>
					<IconButton Icon={BackIcon} className="-ml-2.5" onClick={() => setSettingsActive(false)} />
					<div className="font-primary text-lg font-black text-primary text-left">Settings</div>
				</div>
				<div className="flex flex-col gap-2 w-full px-3 py-3">
					<div key="theme" className="w-full flex flex-col gap-2 items-start">
						<button className={`w-full rounded-xl flex flex-row items-center gap-3 py-2 text-base font-primary font-medium text-primary h-[45px] hover:bg-primary-100 hover:border-1 hover:border-primary-100 transition-[background] ease-in-out duration-300`} onClick={() => (isDarkMode ? setTheme(ThemeState.light) : setTheme(ThemeState.dark))}>
							<div className="w-full flex flex-row justify-between items-center">
								<div className="flex flex-row items-center">
									<div className="w-[42px]">
										<DarkModeIcon className="fill-primary w-[18px] h-[18px] m-auto opacity-50" />
									</div>
									<div>Dark mode</div>
								</div>
								<Switch checked={isDarkMode} onChange={(checked) => setTheme(checked ? ThemeState.dark : ThemeState.light)} className={`${isDarkMode ? "bg-success-green" : "bg-primary"} relative inline-flex h-[20px] w-[36px] items-center rounded-full mr-2`}>
									<span className={`${isDarkMode ? "translate-x-[16px]" : "translate-x-[2px]"} inline-block h-[18px] w-[18px] transform rounded-full bg-white transition`} />
								</Switch>
							</div>
						</button>
					</div>
					{menuItems.map((menuItem) => {
						return (
							<div key={menuItem.title} className="w-full flex flex-col gap-2 items-start">
								<button className={`w-full rounded-xl flex flex-row items-center gap-3 py-2 text-base font-primary font-medium text-primary h-[45px] hover:bg-primary-100 hover:border-1 hover:border-primary-100 transition-[background] ease-in-out duration-300`} onClick={() => buttonOnClick(menuItem.title)}>
									<div className="w-full flex flex-row justify-between items-center">
										<div className="flex flex-row items-center">
											<div className="w-[42px]">
												<menuItem.icon className="fill-primary w-[18px] h-[18px] m-auto opacity-50" />
											</div>
											<div>{menuItem.title}</div>
										</div>
									</div>
								</button>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default SettingsSidebar;
