import { useLayout } from "@providers/LayoutContext";
import IconButton from "@components/common/IconButton";
import { Textfit } from "react-textfit";
import { useAuth } from "@providers/AuthContext";
import { Menu } from 'lucide-react';

interface TitleHeaderProps {
	title: string;
}

const TitleHeader: React.FC<TitleHeaderProps> = ({ title }) => {
	const { isSidebarHidden, setSidebarHidden, navigationBarHeight } = useLayout();
	const { authUser } = useAuth();
	return (
		<>
			<div className="sticky top-0 bg-white/5 backdrop-blur-lg z-20 sm:hidden">
				<div className="flex flex-col gap-3 items-center border-b border-primary-100" style={{ height: navigationBarHeight + "px" }}>
					<div className="flex flex-row justify-between w-full p-3 items-center h-full">
						<div className="flex flex-row items-center justify-between w-full">
							<div className="flex flex-row gap-2 items-center justify-center min-w-0 flex-grow">
								<IconButton Icon={Menu} forcedIconSize={24} onClick={() => setSidebarHidden(false)} />
								<div className="flex flex-row items-center gap-1 w-full justify-between">
									<Textfit max={18} mode="single" className="font-primary text-lg font-black text-primary text-left truncate">
										{title}
									</Textfit>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TitleHeader;
