import { useAuth } from "@providers/AuthContext";
import TitleHeader from "@components/common/TitleHeader";
import SectionLayout from "@layouts/SectionLayout";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { PUBSubscriber, PUBSubscriberExportInfo } from "@models/Models";
import { DataType, FilteringMode, SortDirection, SortingMode, Table, useTable, useTableInstance } from "ka-table";
import { useEffect, useState } from "react";
import "./KaTableMain.scss";
import { ICellTextProps } from "ka-table/props";
import { kaPropsUtils } from "ka-table/utils";
import { generateToast } from "@utils/managers/ToastManager";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const GrowSubscribers = () => {
	const { activeNewsletter } = useAuth();
	const [pageIndex, setPageIndex] = useState<number>(1);
	const [totalSubscribers, setTotalSubscribers] = useState<number>(0);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<PUBSubscriber[]>([]);
	const [subscriberExportInfo, setSubscriberExportInfo] = useState<PUBSubscriberExportInfo | undefined>(undefined);
	const [isExportCSVLoading, setExportCSVLoading] = useState<boolean>(false);
	const [isNewExporCSVLoading, setNewSubscriberCSVLoading] = useState<boolean>(false);

	useEffect(() => {
		analyticsManager.recordEvent(kAnalyticsConstants.Grow.subscribersViewed);
		getSubscriberExportInfo();
	}, []);

	const table = useTable({
		onDispatch: async (action) => {
			if (pageIndex !== -1 && !isLoading) {
				if (action.type === LOAD_MORE_DATA) {
					table.showLoading();
					setLoading(true);
					networkManager
						.getSubscriberList(activeNewsletter?.id.toString(), pageIndex)
						.then((response) => {
							if (response.next) {
								const parsedUrl = new URL(response.next);
								const page = parsedUrl.searchParams.get("page");
								setPageIndex(parseInt(page!));
							} else {
								setPageIndex(-1);
							}
							setTotalSubscribers(response.count);
							setData([...data, ...response.results.subscriber_list]);
							table.hideLoading();
							setLoading(false);
						})
						.catch((_error) => {
							generateToast({ status: "error", title: "Oops! Something went wrong", message: "Make sure you copy the 6 digit code at the bottom of the magic link email we previously sent." });
							setLoading(false);
						});
				}
			}
		},
	});

	const LOAD_MORE_DATA = "LOAD_MORE_DATA";

	const formatDate = (value: string) => {
		var date = moment(value);
		var formattedDate = date.format("LLL");
		return formattedDate;
	};

	const columns = [
		{
			key: "selection-cell",
			isFilterable: false,
			width: 50,
		},
		{ key: "subscriber_email_address", title: "Subscriber Email", dataType: DataType.String },
		{ key: "subscriber_join_date", title: "Join Date", dataType: DataType.Date, sortDirection: SortDirection.Descend },
		{ key: "campaign_type", title: "Campaign", dataType: DataType.String },
	];

	const getCampaignType = (campaign_type: string) => {
		if (campaign_type == "free") {
			return "Free Partnership";
		} else if (campaign_type == "affiliate_engaged") {
			return "Pay per Engaged Subscriber";
		} else if (campaign_type == "affiliate") {
			return "Pay per Subscriber";
		} else if (campaign_type == "cross_promo") {
			return "Cross-promo";
		}
		return campaign_type;
	};

	const SelectionCell = ({ rowKeyValue, isSelectedRow, selectedRows }: ICellTextProps) => {
		const table = useTableInstance();
		return (
			<input
				type="checkbox"
				checked={isSelectedRow}
				onChange={(event: any) => {
					if (event.nativeEvent.shiftKey) {
						table.selectRowsRange(rowKeyValue, [...selectedRows].pop());
					} else if (event.currentTarget.checked) {
						table.selectRow(rowKeyValue);
					} else {
						table.deselectRow(rowKeyValue);
					}
				}}
			/>
		);
	};

	const SelectionHeader = () => {
		const table = useTableInstance();
		const areAllRowsSelected = kaPropsUtils.areAllFilteredRowsSelected(table.props);

		return (
			<input
				type="checkbox"
				checked={areAllRowsSelected}
				onChange={(event) => {
					if (event.currentTarget.checked) {
						table.selectAllFilteredRows();
					} else {
						table.deselectAllFilteredRows();
					}
				}}
			/>
		);
	};

	const getSubscriberExportInfo = () => {
		networkManager.subscriberExportInfo(activeNewsletter!.id.toString()).then((response) => {
			setSubscriberExportInfo(response);
		});
	};

	const getCSVFileName = () => {
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, "0");
		let mm = String(today.getMonth() + 1).padStart(2, "0");
		let yyyy = today.getFullYear();
		return "Meco_subscriber_export_" + dd + "_" + mm + "_" + yyyy;
	};

	const getExportData = () => {
		const selectedRows = kaPropsUtils.getSelectedData(table.props);
		if (!selectedRows || selectedRows.length === 0) {
			return data;
		}
		return selectedRows;
	};

	const onExportCSV = () => {
		const selectedRows = kaPropsUtils.getSelectedData(table.props);
		if (selectedRows.length > 0) {
			exportSelectedRows();
			return;
		}

		setExportCSVLoading(true);

		networkManager
			.subscriberExport(activeNewsletter!.id.toString(), false)
			.then(() => {
				analyticsManager.recordEvent(kAnalyticsConstants.Grow.Subscribers.exported, { contact_count: totalSubscribers, newsletter_id: activeNewsletter!.id.toString() });
				setExportCSVLoading(false);
			})
			.catch((_error) => {
				setExportCSVLoading(false);
				generateToast({ status: "error", title: "Oops! Something went wrong", message: "We couldn't export all subscribers. Please try again later." });
			});
	};

	const exportNewSubscribersOnly = () => {
		setNewSubscriberCSVLoading(true);
		networkManager
			.subscriberExport(activeNewsletter!.id.toString(), true)
			.then(() => {
				setNewSubscriberCSVLoading(false);
				analyticsManager.recordEvent(kAnalyticsConstants.Grow.Subscribers.newSelectionExported);
			})
			.catch((_error) => {
				setNewSubscriberCSVLoading(false);
				generateToast({ status: "error", title: "Oops! Something went wrong", message: "We couldn't export new subscribers. Please try again later." });
			});
	};

	const exportSelectedRows = () => {
		const headers = [
			{ label: "Subscriber Email", key: "subscriber_email_address" },
			{ label: "Join Date", key: "subscriber_join_date" },
			{ label: "Campaign", key: "campaign_type" },
		];

		const exportedData = getExportData();
		const transformedData = exportedData.map((row) => {
			const renamedRow = {};
			headers.forEach((header) => {
				renamedRow[header.label] = row[header.key];
			});
			return renamedRow;
		});

		const csv = Papa.unparse({
			fields: headers.map((header) => header.label),
			data: transformedData,
		});

		analyticsManager.recordEvent(kAnalyticsConstants.Grow.Subscribers.selectionExported, { contact_count: exportedData.length, newsletter_id: activeNewsletter!.id.toString() });

		const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		saveAs(blob, getCSVFileName());
	};

	const getNewSubscriberString = (subscriberNumber: number) => {
		if (subscriberNumber === 1) {
			return "1 new subscriber";
		} else {
			return subscriberNumber + " new subscribers";
		}
	};

	return (
		<>
			<TitleHeader title="Grow with Meco" />
			<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12 min-h-[100vh]">
				<div className="flex flex-col gap-5 m-auto">
					<SectionLayout sectionTitle="Your Subscribers">
						{(subscriberExportInfo?.subscriber_count_since_last_csv_export ?? 0) > 0 && (
							<div className="w-full border-[1px] border-primary-100 rounded-[10px] p-[20px] bg-success-green/30">
								<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-between">
									<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center">
										<div className="text-primary font-medium font-primary text-lg">You have {getNewSubscriberString(subscriberExportInfo!.subscriber_count_since_last_csv_export!)} since your last export</div>
										<div className="text-primary-500 font-regular font-primary text-sm">Last export was on {moment(subscriberExportInfo!.last_csv_export!).format("LLL")}</div>
									</div>
									<button disabled={isNewExporCSVLoading} className={`text-white font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center bg-success-green whitespace-nowrap no-underline ${isNewExporCSVLoading ? "opacity-50" : "opacity-100"}`} onClick={() => exportNewSubscribersOnly()}>
										<div className="relative">
											{isNewExporCSVLoading && <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white absolute m-auto left-0 right-0" />}
											<div className={isNewExporCSVLoading ? "opacity-0" : "opacity-100"}>Export new subscribers only</div>
										</div>
									</button>
								</div>
							</div>
						)}
						<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-between py-2">
							<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center">
								<div className="text-primary font-medium font-primary text-lg">Total Subscribers: {totalSubscribers}</div>
							</div>
							<div className="flex flex-col gap-3 items-center w-full sm:w-auto">
								<button disabled={isExportCSVLoading} className={`text-white font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center bg-success-green whitespace-nowrap no-underline ${isExportCSVLoading ? "opacity-50" : "opacity-100"}`} onClick={() => onExportCSV()}>
									<div className="relative">
										{isExportCSVLoading && <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white absolute m-auto left-0 right-0" />}
										<div className={isExportCSVLoading ? "opacity-0" : "opacity-100"}>Export CSV</div>
									</div>
								</button>
							</div>
						</div>
						<div className="ka-table">
							<Table
								table={table}
								data={data}
								columns={columns}
								format={({ column, value }) => {
									if (column.key === "subscriber_join_date") {
										return formatDate(value);
									}
									if (column.key === "campaign_type") {
										return getCampaignType(value);
									}
								}}
								sortingMode={SortingMode.Single}
								rowKeyField={"subscriber_email_address"}
								filteringMode={FilteringMode.FilterRow}
								virtualScrolling={{
									enabled: true,
								}}
								singleAction={{ type: LOAD_MORE_DATA }}
								loading={{
									enabled: isLoading,
									text: "Loading subscribers...",
								}}
								childComponents={{
									cellText: {
										content: (props) => {
											if (props.column.key === "selection-cell") {
												return <SelectionCell {...props} />;
											}
										},
									},
									headCell: {
										content: (props) => {
											if (props.column.key === "selection-cell") {
												return <SelectionHeader />;
											}
										},
									},
									tableWrapper: {
										elementAttributes: () => ({
											onScroll: (event, { baseFunc, dispatch }) => {
												baseFunc(event);
												const element = event.currentTarget;
												const BOTTOM_OFFSET = 20;
												if (element.offsetHeight + element.scrollTop >= element.scrollHeight - BOTTOM_OFFSET) {
													dispatch({ type: LOAD_MORE_DATA });
												}
											},
											style: { maxHeight: 400 },
										}),
									},
								}}
							/>
						</div>
					</SectionLayout>
				</div>
			</div>
		</>
	);
};

export default GrowSubscribers;
