import { FC, useState, useEffect } from "react";
import collageBalloon from "@images/collage_paywall_balloon.png";
import { ReactComponent as MecoLogo } from "@images/meco_logo.svg";
import { ReactComponent as RightArrow } from "@images/right_arrow.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import ParticlesBackground from "@components/common/ParticlesBackground";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { kStringConstants } from "@utils/constants/StringConstants";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import * as userManager from "@utils/managers/UserManager";
import { Link, useNavigate } from "react-router-dom";
import { generateToast } from "@utils/managers/ToastManager";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const AccountCreationView = () => {
	const [isLoading, setLoading] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [signUpUserModel, setSignUpUserModel] = useState<Record<string, string>>({
		email: "",
		first_name: "",
		last_name: "",
		newsletter_name: "",
		newsletter_url: "",
	});

	const navigate = useNavigate();

	useEffect(() => {
		userManager.logOutUser();
	}, []);

	const inputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const fieldValue: string = e.currentTarget.value;
		const fieldId: string = e.currentTarget.id;

		const updatedModel = {
			...signUpUserModel,
			[fieldId]: fieldValue,
		};

		const isEmailValid = checkEmail(updatedModel.email);
		const isNewsletterUrlValid = checkNewsletterUrl(updatedModel.newsletter_url);

		const areAllFieldsFilled = Object.values(updatedModel).every((value) => value.trim() !== "");

		setSignUpUserModel(updatedModel);
		setIsFormValid(isEmailValid && isNewsletterUrlValid && areAllFieldsFilled);
	};

	function checkNewsletterUrl(newsletterUrl: string) {
		const regex = /\S/;
		return regex.test(newsletterUrl) && newsletterUrl != "";
	}

	const goToMagicLink = (emailAddress) => {
		navigate("/magic-link", { state: { email: emailAddress } });
	};

	const continueButtonOnClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!isFormValid) {
			return;
		}

		setLoading(true);

		networkManager
			.signUpUser(signUpUserModel)
			.then((_response) => {
				recordEvent(kAnalyticsConstants.Onboarding.accountSignUpSuccessful, { email: signUpUserModel.email });
				recordEvent(kAnalyticsConstants.Onboarding.magicLinkSent, { email: signUpUserModel.email, is_login: false });
				goToMagicLink(signUpUserModel.email);
			})
			.catch((error) => {
				setLoading(false);
				generateSignUpError(error);
				recordEvent(kAnalyticsConstants.Onboarding.accountSignUpError, { error: error.message, email: signUpUserModel.email });
			});
	};

	const generateSignUpError = (error) => {
		var title = kStringConstants.Common.errorAlertTitle;
		var message = kStringConstants.Common.errorAlertMessage;

		if (error == kErrorConstants.signUp.existingAccount) {
			title = "Account already exists!";
			message = "To access this account please log in.";
		}

		generateToast({ status: "error", title: title, message: message });
	};

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	return (
		<div>
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="flex flex-col md:gap-0 gap-10 items-center justify-center p-3">
					<div className="text-white font-regular font-primary text-2xl"><span className="font-black text-3xl">Meco</span> for Publishers</div>
					<div className="meco_container text-center max-w-[500px] py-[40px] md:p-[60px]">
						<div className="flex flex-col gap-2 items-center">
							<div className="text-white font-black font-primary text-3xl">Become a partner.</div>
							<div className="text-white/50 font-regular font-primary text-lg">Sign up your newsletter to start earning and growing with Meco.</div>
							<form className="flex flex-col mt-4 gap-4" onSubmit={continueButtonOnClick}>
								<div className="flex flex-col gap-3 flex-grow">
									<div className="flex flex-col sm:flex-row gap-3 flex-grow">
										<input type="text" disabled={isLoading} id="first_name" value={signUpUserModel.first_name} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="First Name" />
										<input type="text" disabled={isLoading} id="last_name" value={signUpUserModel.last_name} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Last Name" />
									</div>
									<input type="email" disabled={isLoading} id="email" value={signUpUserModel.email} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Email address" />
									<input type="text" disabled={isLoading} id="newsletter_name" value={signUpUserModel.newsletter_name} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Newsletter Name" />
									<input type="text" disabled={isLoading} id="newsletter_url" value={signUpUserModel.newsletter_url} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Newsletter URL" />
								</div>
								<div className="flex flex-col gap-3 flex-grow">
									<button type="submit" disabled={!isFormValid || isLoading} className={`cta-button ${isFormValid ? "opacity-100" : "opacity-50"}`}>
										{isLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white m-auto" /> : <div>Continue</div>}
									</button>
									<div className="text-white/50 font-regular font-primary text-xs text-center">
										By joining Meco, you consent to our{" "}
										<a href="https://www.meco.app/terms" target="_blank" className="text-white/50 hover:text-success-green transition ease-in-out">
											terms & conditions
										</a>
									</div>
								</div>
							</form>

							<div className="text-white font-regular font-primary text-lg mt-5">
								Already have an account?{" "}
								<Link to="/login" className="text-success-green hover:text-success-green transition ease-in-out">
									Log In
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountCreationView;
