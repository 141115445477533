import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import * as networkManager from "./networking/NetworkManager";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";

export function logOutUser(resetDistinctId?: boolean, completion?: () => void) {
	networkManager.logOutUser().finally(() => {
		if (resetDistinctId) {
			mixpanel.reset();
		}
		localStorage.clear();
		const nextPath = sessionStorage.getItem(kLocalStorageKeys.Session.nextPath);
		sessionStorage.clear();

		if (nextPath) {
			//keep next path
			sessionStorage.setItem(kLocalStorageKeys.Session.nextPath, nextPath);
		}

		Sentry.configureScope((scope) => scope.setUser(null));

		if (completion) {
			completion();
		}
	});
}
