type SectionLayoutProps = {
	sectionTitle: string;
	sectionDescription?: string;
	sectionRightDiv?: React.ReactNode;
	id?: string;
	children: React.ReactNode;
};

const SectionLayout = ({ sectionTitle, sectionDescription, sectionRightDiv, id, children }: SectionLayoutProps) => {
	return (
		<div className="flex flex-col gap-4 w-full border border-primary-100 rounded-xl p-3 sm:p-4.5 bg-surface-200" id={id}>
			<div className="flex flex-col sm:flex-row gap-3 justify-between items-start sm:items-center pb-4 border-b border-primary-100">
				<div className="flex flex-col gap-1">
					<div className="font-primary font-black text-xl text-primary">{sectionTitle}</div>
					{sectionDescription && <div className="font-primary font-regular text-sm text-primary-500">{sectionDescription}</div>}
				</div>
				{sectionRightDiv}
			</div>
			{children}
		</div>
	);
};

export default SectionLayout;
