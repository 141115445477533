import { useAuth } from "@providers/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import TitleHeader from "@components/common/TitleHeader";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const GrowJoin = () => {
	const { growActive } = useAuth();

	if (growActive) {
		return <Navigate to="/grow" />;
	}

	const onButtonClick = () => {
		recordEvent(kAnalyticsConstants.Grow.growApplyClicked);
		window.open("https://tally.so/r/mJ1kNd", "_blank");
	};

	return (
		<>
			<TitleHeader title="Grow with Meco" />
			<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12 min-h-[100vh]">
				<div className="flex flex-col gap-5 m-auto">
					<div className="w-full border-[1px] border-primary-100 rounded-[10px] p-[20px] bg-surface-200">
						<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-between">
							<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center">
								<div className="text-primary font-medium font-primary text-lg">Promote your newsletter on the Meco app</div>
								<div className="text-primary-500 font-regular font-primary text-sm">We drive thousands of subscribers to newsletters every month through the Meco app. If you’d like your newsletter to be featured to good-fit readers on Meco please submit your details and we’ll get back to you.</div>
							</div>
							<div className="flex flex-col gap-3 items-center w-full sm:w-auto">
								<button className="text-white font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center bg-success-green whitespace-nowrap" onClick={onButtonClick}>
									Apply now
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GrowJoin;
