import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";

const GrowRoute = ({...rest}) => {
    const { growActive } = useAuth();

    if (growActive === undefined) {
		return;
	}

    if (growActive) {
        return <Outlet {...rest} />;
    }

    return <Navigate to="/grow/join" />;

};

export default GrowRoute;
