import React, { useState, useEffect, FC, Fragment, useRef } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../../common/IconButton";
import { useAuth } from "@providers/AuthContext";
import { useHotkeys } from "react-hotkeys-hook";
import { Dialog, DialogBackdrop, DialogPanel, Field, Input, Label } from "@headlessui/react";
import ListboxView from "@components/common/ListboxView";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { PUBIntegrationsResponse } from "@models/Models";
import "./GrowIntegrations.scss";
import Alert, { AlertState } from "@components/common/Alert";
import { generateToast } from "@utils/managers/ToastManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
interface GrowIntegrationsModalProps {
	isShow: boolean;
	integrationsResponse?: PUBIntegrationsResponse;
	setIntegrationResponse: (integrationsResponse: PUBIntegrationsResponse) => void;
	onClose: () => void;
}

const GrowIntegrationsModal: FC<GrowIntegrationsModalProps> = ({ isShow, integrationsResponse, setIntegrationResponse, onClose }) => {
	const { activeNewsletter } = useAuth();
	const [selectedIntegrationId, setSelectedIntegrationId] = useState<number | undefined>(undefined);
	const [isFormValid, setFormValid] = useState(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const refs = useRef<Record<string, HTMLElement>>({});
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	const dropdownPlaceholder = "Select platform...";

	useHotkeys("Escape", () => onModalClose());

	useEffect(() => {
		if (!isShow) {
			return;
		}

		if (integrationsResponse?.newsletter_integration?.integration_id) {
			setSelectedIntegrationId(integrationsResponse.newsletter_integration!.integration_id);
		} else {
			setSelectedIntegrationId(undefined);
		}

		setFormValid(false);
	}, [isShow]);

	useEffect(() => {
		refs.current = {};
		setFormValid(false);
	}, [selectedIntegrationId]);

	const onModalClose = () => {
		onClose();
	};

	const handleIntegrationSelectionChange = (integrationName) => {
		if (integrationName == dropdownPlaceholder) {
			setSelectedIntegrationId(undefined);
			return;
		}

		const selectedIntegration = integrationsResponse!.integrations.find((x) => x.name === integrationName);
		setSelectedIntegrationId(selectedIntegration?.id ?? undefined);
	};

	const getIntegrationOptions = (): string[] => {
		return [dropdownPlaceholder, ...integrationsResponse!.integrations.sort((a, b) => a.sort_index - b.sort_index).map((x) => x.name!)];
	};

	function getSelectedIntegration() {
		return integrationsResponse?.integrations.find((x) => x.id == selectedIntegrationId);
	}

	const generateFields = () => {
		let selectedIntegration = getSelectedIntegration();
		let fields = selectedIntegration?.fields ?? [];
		let isExistingIntegration = selectedIntegration?.id == integrationsResponse?.newsletter_integration?.integration_id;
		var formsToBeAdded: JSX.Element[] = [];

		if (selectedIntegration?.name == "Zapier") {
			formsToBeAdded.push(
				<Field className={"font-primary font-regular border border-primary-100 rounded-xl p-3 bg-surface-200 mb-3"}>
					<Label className="text-base font-medium text-primary">API key</Label>
					<Input type="text" id={selectedIntegration?.id.toString()} value={integrationsResponse?.zapierKey} disabled className={"mt-3 block w-full rounded-[12px] bg-primary/5 py-1.5 px-3 text-sm/6 text-primary focus:outline-none focus:outline-2 focus:-outline-offset-2 focus:outline-primary-200 border border-primary-200"} />
				</Field>
			);
		} else {
			for (let field of fields) {
				var existingValue: string | undefined = undefined;
				if (isExistingIntegration) {
					let userIntegrationFields = integrationsResponse?.newsletter_integration?.fields ?? {};
					let existingUserField = Object.keys(userIntegrationFields).filter((x) => x == field)[0];
					existingValue = userIntegrationFields[existingUserField];
				}
				formsToBeAdded.push(
					<Field className={"font-primary font-regular border border-primary-100 rounded-xl p-3 bg-surface-200 mb-3"}>
						<Label className="text-base font-medium text-primary">{field}</Label>
						<Input
							type="text"
							key={`${selectedIntegrationId}_${field}`}
							ref={(el) => {
								if (el) refs.current[`${field}`] = el;
							}}
							id={`${selectedIntegration?.id}_${field}`}
							defaultValue={existingValue}
							className={"mt-3 block w-full rounded-[12px] bg-primary/5 py-1.5 px-3 text-sm/6 text-primary focus:outline-none focus:outline-2 focus:-outline-offset-2 focus:outline-primary-200 border border-primary-200"}
							onChange={formValueChanged}
						/>
					</Field>
				);
			}
		}

		return formsToBeAdded;
	};

	const getFieldValues = () => {
		const values = Object.keys(refs.current).map((key) => ({
			id: key,
			value: (refs.current[key] as HTMLInputElement).value,
		}));
		return values;
	};

	function formValueChanged() {
		let formValues = getFieldValues();
		validateForm(formValues);
	}

	function validateForm(formValues) {
		if (integrationsResponse?.newsletter_integration && JSON.stringify(integrationsResponse.newsletter_integration.fields) === JSON.stringify(formValues)) {
			setFormValid(false);
			return;
		}

		const values = formValues.map((x) => x.value);
		const regex = /^\s*$/g;
		const allMatch = values.every((value) => !regex.test(value));
		setFormValid(allMatch);
	}

	const isDeleteAvailable = () => integrationsResponse?.newsletter_integration?.integration_id === selectedIntegrationId && selectedIntegrationId !== undefined;

	const deleteIntegration = () => {
		setLoading(true);
		networkManager
			.deleteIntegration(activeNewsletter!.id.toString())
			.then((integrations) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Grow.Integrations.removed);
				generateToast({ status: "success", title: "Integration removed", message: "Make sure to turn on your alerts." });
				setIntegrationResponse(integrations);
				setLoading(false);
				onModalClose();
			})
			.catch((error) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Grow.Integrations.removedError);
				setLoading(false);
				generateToast({ status: "error", title: "Oops! Something went wrong", message: "We were unable to remove your integration. Please try again or contact support." });
			});
	};

	const submitIntegration = (isForce: boolean = false) => {
		let selectedIntegration = getSelectedIntegration();

		if (!isForce && integrationsResponse?.newsletter_integration?.integration_id != undefined && selectedIntegrationId != integrationsResponse?.newsletter_integration?.integration_id) {
			//If integrations change
			let oldIntegration = integrationsResponse?.integrations.find((x) => x.id == integrationsResponse?.newsletter_integration?.integration_id);

			setAlertState({ isShow: true, title: "You're changing your integration", message: "We will replace your current " + oldIntegration?.name + " integration with " + selectedIntegration?.name + ". Would you like to continue?", actionButton: "Continue", dismissButton: "Cancel", actionButtonAction: () => submitIntegration(true) });
			return;
		}

		if (!isFormValid) {
			return;
		}

		var formValues = getFieldValues();

		var postDict = {};

		for (let key in formValues) {
			postDict[formValues[key]["id"]] = formValues[key]["value"];
		}
		postDict["id"] = selectedIntegrationId;

		setLoading(true);
		networkManager
			.postIntegration(activeNewsletter!.id.toString(), postDict)
			.then((integrations) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Grow.Integrations.added);
				setLoading(false);
				generateToast({ status: "success", title: "Integration created" });
				setIntegrationResponse(integrations);
				onClose();
			})
			.catch((_error) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Grow.Integrations.addedError);
				setLoading(false);
				generateToast({ status: "error", title: "Oops! Something went wrong", message: "We were unable to create your integration. Please try again or contact support." });
			});
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Dialog open={isShow} onClose={() => onClose()} transition className="relative z-40 transition duration-300 ease-out data-[closed]:opacity-0">
				<DialogBackdrop className="fixed inset-0 bg-black/80" />
				<div className="fixed inset-0 flex w-screen items-center justify-center p-2 sm:p-4">
					<DialogPanel className="m-auto w-full h-full max-w-4xl transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
						<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
							<div className="text-primary font-medium font-primary text-xl">Set up integration</div>
							<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
						</div>
						<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
							{integrationsResponse && (
								<>
									<div>
										<Field className={"font-primary font-regular border border-primary-100 rounded-xl p-3 bg-surface-200 flex flex-col gap-4"}>
											<div className="flex flex-row items-center gap-3">
												<div className="bg-success-green rounded-full w-[40px] aspect-square flex items-center justify-center font-primary font-medium text-white">1</div>
												<Label className="text-base font-medium text-primary">Select your publishing platform</Label>
											</div>
											<ListboxView selected={integrationsResponse.integrations.find((x) => x.id === selectedIntegrationId)?.name ?? dropdownPlaceholder} setSelected={(selectionString) => handleIntegrationSelectionChange(selectionString)} options={getIntegrationOptions()} className="!w-full" />{" "}
										</Field>
									</div>
									{selectedIntegrationId && (
										<div>
											<Field className={"font-primary font-regular border border-primary-100 rounded-xl p-3 bg-surface-200 flex flex-col gap-4"}>
												<div className="flex flex-row items-center gap-3">
													<div className="bg-success-green rounded-full w-[40px] aspect-square flex items-center justify-center font-primary font-medium text-white">2</div>
													<Label className="text-base font-medium text-primary">Add integration details</Label>
												</div>
												<div dangerouslySetInnerHTML={{ __html: getSelectedIntegration()?.notice_html ?? "" }}></div>
												<div>{generateFields()}</div>
											</Field>
										</div>
									)}
								</>
							)}
						</div>
						<div className="absolute bottom-0 w-full bg-surface backdrop-blur-md border-t border-primary-100 px-4 py-4 pt-3 z-20">
							<div className={`flex flex-row ${isDeleteAvailable() ? "justify-between" : "justify-end"}`}>
								{isDeleteAvailable() && (
									<button
										type="submit"
										disabled={isLoading}
										className={`text-brand-red hover:text-white font-medium font-primary focus:outline-none rounded-xl text-base p-2 px-3 py-2 items-center border-solid border-brand-red whitespace-nowrap bg-transparent hover:bg-brand-red duration transition duration-300 ease-in-out`}
										onClick={() => {
											setAlertState({ isShow: true, title: "Are you sure?", message: "When you remove the " + getSelectedIntegration()?.name + " integration, new Meco subscribers won't be automatically added to your newsletter. Would you like to continue?", actionButton: "Delete", dismissButton: "Cancel", actionButtonAction: () => deleteIntegration() });
										}}>
										<div className="relative">
											{isLoading && <LoadingSpinner className="h-[20px] text-brand-red/20 animate-spin fill-brand-red absolute m-auto left-0 right-0" />}
											<div className={isLoading ? "opacity-0" : "opacity-100"}>Remove Integration</div>
										</div>
									</button>
								)}
								<button type="submit" disabled={!isFormValid || isLoading} className={`text-white font-medium font-primary focus:outline-none rounded-xl text-base p-2 px-3 py-2 items-center bg-success-green whitespace-nowrap ${isFormValid ? "opacity-100" : "opacity-50"}`} onClick={() => submitIntegration()}>
									<div className="relative">
										{isLoading && <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white absolute m-auto left-0 right-0" />}
										<div className={isLoading ? "opacity-0" : "opacity-100"}>Save</div>
									</div>
								</button>
							</div>
						</div>
					</DialogPanel>
				</div>
			</Dialog>
		</>
	);
};

export default GrowIntegrationsModal;
