export default function Tooltip({ message, children, isCenter = true }: { message?: string, children: any, isCenter?: boolean }) {
	return (
		<div className={`relative flex flex-col ${isCenter ? "items-center" : "items-start"} group hover:bg-primary-500 rounded-full cursor-pointer`}> 
			{children}
			<div className={`w-[200px] absolute bottom-2 flex flex-col ${isCenter ? "items-center" : "items-start"} hidden mb-6 group-hover:flex`}>
				<span className="relative z-10 whitespace-no-wrap shadow-lg bg-primary p-3 font-regular font-primary text-fs-body rounded-[10px] text-secondary leading-[20px]">{message}</span>
				<div className={`w-3 h-3 -mt-2 ${isCenter ? "ml-0" : "ml-2"} rotate-45 bg-primary`}></div>
			</div>
		</div>
	);
}
