import { useAuth } from "@providers/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import TitleHeader from "@components/common/TitleHeader";
import EarnJoinModal from "./EarnJoinModal";
import { useState } from "react";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { ToastState, generateToast } from "@utils/managers/ToastManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import LoadingView, { LoadingState } from "@components/common/LoadingView";

const EarnJoin = () => {
	const { earnActive, activeNewsletter } = useAuth();
	const [showJoinModal, setShowJoinModal] = useState(false);
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});

	if (earnActive) {
		return <Navigate to="/earn" />;
	}

	const createReferralLink = (audienceProperties: Record<string, string>) => {
		setShowJoinModal(false);
		setLoadingState({ isLoading: true });
		networkManager
			.createReferralLink(activeNewsletter!.id.toString(), audienceProperties)
			.then((referralUrl) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Earn.referralProgramJoined, { newsletter_id: activeNewsletter!.id.toString(), referral_url: referralUrl.referral_url });
				window.location.reload();
			})
			.catch((error) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Earn.referralProgramJoinError, { newsletter_id: activeNewsletter!.id.toString(), error: error?.message ?? "" });
				setLoadingState({ isLoading: false });
				const errorToastState: ToastState = {
					status: "error",
					title: "Something went wrong",
					message: "We couldn't create your referral link. Please reach us at team@meco.app.",
				};
				generateToast(errorToastState);
			});
	};

	return (
		<>
			<LoadingView loadingState={loadingState} />
			<TitleHeader title="Earn with Meco" />
			<EarnJoinModal
				isShow={showJoinModal}
				onAgreeTerms={(audienceProperties) => createReferralLink(audienceProperties)}
				onClose={() => {
					setShowJoinModal(false);
				}}
			/>
			<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12 sm:min-w-[500px] min-h-[100vh]">
				<div className="flex flex-col gap-5 m-auto">
					<div className="w-full border-[1px] border-primary-100 rounded-[10px] p-[20px] bg-surface-200">
						<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-center">
							<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center">
								<div className="text-primary font-medium font-primary text-lg">Start by getting your referral link</div>
								<div className="text-primary-500 font-regular font-primary text-sm">Get your unique tracking link to use when promoting Meco. Once you have your link, we’ll share the current rate we are paying per referral and provide guidance on how best to promote Meco.</div>
							</div>
							<div className="flex flex-col gap-3 items-center w-full sm:w-auto">
								<button
									className="text-white font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center bg-success-green whitespace-nowrap"
									onClick={() => {
										analyticsManager.recordEvent(kAnalyticsConstants.Earn.getLinkClicked, { newsletter_id: activeNewsletter!.id.toString() });
										setShowJoinModal(true);
									}}>
									Get referral link
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EarnJoin;
