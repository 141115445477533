import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { Fragment } from "react";
import { ReactComponent as ListIcon } from "@images/list_icon.svg";
import { ReactComponent as CheckmarkIcon } from "@images/checmark_raw_icon.svg";

interface ListboxViewProps {
	options: string[];
	selected: string;
	setSelected: (selectedOption: string) => void;
	className?: string;
}

const ListboxView = ({ options, selected, setSelected, className }: ListboxViewProps) => {
	return (
		<Listbox value={selected} onChange={setSelected}>
			<ListboxButton className={`relative block w-full rounded-lg bg-surface py-1.5 pr-8 pl-3 text-left text-sm font-primary text-primary focus:outline-none data-[focus]:outline-2 focus:-outline-offset-2 focus:outline-primary-200 ${className} h-[40px] border border-solid border-primary-100`}>
				{selected}
				<ListIcon className="group pointer-events-none absolute top-[12px] right-2.5 size-3 fill-primary-500" aria-hidden="true" />
			</ListboxButton>
			<ListboxOptions anchor="bottom" transition className={"w-[var(--button-width)] rounded-[12px] border border-primary-200 bg-secondary p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none transition duration-100 ease-in leave:closed:opacity-0"}>
				{options.map((option, idx) => (
					<ListboxOption key={idx} value={option} className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none focus:bg-primary-200 hover:bg-primary-100">
						<CheckmarkIcon className="invisible size-[12px] fill-success-green group-data-[selected]:visible" />
						<div className="text-sm font-primary text-primary">{option}</div>
					</ListboxOption>
				))}
			</ListboxOptions>
		</Listbox>
	);
};

export default ListboxView;
