import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";
import LoadingView from "@components/common/LoadingView";

const EarnRoute = ({...rest}) => {
    const { earnActive } = useAuth();

    if (earnActive === undefined) {
		return;
	}

    if (earnActive) {
        return <Outlet {...rest} />;
    }

    return <Navigate to="/earn/join" />;

};

export default EarnRoute;
