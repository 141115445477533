import mixpanel from "mixpanel-browser";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";
import { PUBProfile } from "@models/Models";
import moment from "moment";

export const recordEvent = (name: string, properties: Record<string, any> = {}) => {
	mixpanel.track(name, properties);
};

export const analyticsCompletedSignUp = (profile: PUBProfile) => {
	createMixpanelProfile(profile);
	recordEvent(kAnalyticsConstants.Onboarding.loginSuccessful);
};

const createMixpanelProfile = (profile: PUBProfile) => {
	mixpanel.alias(profile.id, mixpanel.get_distinct_id());
	mixpanel.identify(mixpanel.get_distinct_id());
	refreshSuperProperties(profile);
};

export const refreshSuperProperties = (profile: PUBProfile) => {
	let firstName = profile.first_name;
	let lastName = profile.last_name;
	let email = profile.email;
	let newsletters = profile.newsletters;
	let newsletterNames = newsletters.map((x) => x.name);
	let newsletterIds = newsletters.map((x) => x.id);
	let userId = profile.id;

	if (firstName == null || lastName == null || email == null) {
		return;
	}

	mixpanel.people.set({ $first_name: firstName, $last_name: lastName, $email: email, user_id: userId, newsletters: newsletterNames, newsletter_ids: newsletterIds });

	mixpanel.register({ user_id: userId, newsletters: newsletterNames, newsletter_ids: newsletterIds });
};

export const optInOptOutAnalytics = (optedIn: boolean) => {
	if (mixpanel.has_opted_out_tracking() && optedIn) {
		mixpanel.opt_in_tracking();
		return;
	}

	if (!mixpanel.has_opted_out_tracking() && !optedIn) {
		mixpanel.opt_out_tracking();
		return;
	}
};

export const optInStatus = () => {
	return !mixpanel.has_opted_out_tracking();
};
