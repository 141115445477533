export const kAnalyticsConstants = {
    App: {
        sessionStarted: "MfP - Session Started",
        newsletterSwitched: "MfP - Newsletter Switched",
    },
	Onboarding: {
		accountLoginError: "ONB - Account - Login Error",
		accountLoginSuccessful: "ONB - Account - Login Successful",
		accountSignUpError: "ONB - Account - Sign up Error",
		accountSignUpSuccessful: "ONB - Account - Sign up Successful",
		magicLinkSent: "ONB - Magic Link Sent",
		resentLink: "ONB - Magic Link - Resent Link",
		magicCodeEntered: "ONB - Magic Link - Magic Code Entered",
		magicCodeError: "ONB - Magic Link - Magic Code Error",
		magicCodeValidated: "ONB - Magic Link - Magic Code Validated",
		magicLinkValidated: "ONB - Magic Link - Validated",
		magicLinkValidationError: "ONB - Magic Link - Validation Error",
		loginSuccessful: "ONB - Login Successful",
    },
    Earn: {
        getLinkClicked: "ERN - Earn Get Link Clicked",
        referralProgramJoinViewed: "ERN - Join - Viewed",
        referralProgramPaymentInfo: "ERN - Join - Payment Info Submitted",
        referralProgramPaymenInforError: "ERN - Join - Payment Info Submit Error",
        referralProgramJoined: "ERN - Join - Referral Program Joined",
        referralProgramJoinError: "ERN - Join - Referral Program Join Error",
        assetsViewed: "ERN - Assets Viewed",
        earnDashboardClicked: "ERN - Dashboard Viewed",
        earnPayoutsClicked: "ERN - Payouts Viewed",
        earnPayoutSettingsClicked: "ERN - Payout Settings Viewed",
    },
    Grow: {
        growApplyClicked: "GRW - Grow Apply Clicked",
        dashboardClicked: "GRW - Dashboard Viewed",
        subscribersViewed: "GRW - Subscribers Viewed",
        Subscribers: {
            exported:  "GRW - Subscribers - All Exported",
            newSelectionExported:  "GRW - Subscribers - New Subscriber Exported",
            selectionExported:  "GRW - Subscribers - Selection Exported",
        },
        paymentSettingsTapped: "GRW - Payment Settings Tapped",
        alertsViewed: "GRW - Alerts Viewed",
        alertsChanged:  "GRW - Alerts Changed",
        integrationsViewed:  "GRW - Integrations Viewed",
        Integrations: {
            added: "GRW - Integrations - Added",
            addedError: "GRW - Integrations - Add Error",
            removed: "GRW - Integrations - Removed",
            removedError: "GRW - Integrations - Remove Error",
        },
    },
}