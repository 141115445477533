import React, { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { NLWallpaper } from "@models/Models";
import sunsetWallpaper from "@images/wallpapers/wallpaper1.jpg";
import GreeneryWallpaper from "@images/wallpapers/wallpaper2.jpg";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import createPersistedState from "use-persisted-state";
import { ThemeState } from "@models/Enums";
import { Outlet } from "react-router-dom";

interface LayoutContextProps {
  isSidebarHidden: boolean;
  sideBarWidth: number;
  setSidebarHidden: (isSidebarHidden: boolean) => void;
  isMailListHidden: boolean;
  mailListWidth: number;
  setMailListHidden: (isMailListHidden: boolean) => void;
  navigationBarHeight: number;
  searchNavigationBarHeight: number;
  isSettingsActive: boolean;
  setSettingsActive: (settingsActive: boolean) => void;
  wallpaper: NLWallpaper;
  setWallpaper: (wallpaper: NLWallpaper) => void;
  wallpapers: NLWallpaper[];
  setTheme: (theme: ThemeState) => void;
  theme: ThemeState;
  isAudioPlayerActive: boolean;
  setAudioPlayerActive: (isActive: boolean) => void;
}

const LayoutContext = createContext<LayoutContextProps | undefined>(undefined);
const useWallpaper = createPersistedState(kLocalStorageKeys.AppSettings.activeWallpaper);
const useTheme = createPersistedState(kLocalStorageKeys.AppSettings.theme);

export const LayoutProvider = () => {
  const sideBarWidth = 300;
  const mailListWidth = 400;
  const navigationBarHeight = 75;
  const searchNavigationBarHeight = 140;
  const greenShapeWallpaper: NLWallpaper = { imageId: 5, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/green_shape.jpg", sidebarColor: "sm:bg-sidebar/90", containerColor: "bg-secondary" };

  const [isSidebarHidden, setSidebarHidden] = React.useState<boolean>(false);
  const [isMailListHidden, setMailListHidden] = React.useState<boolean>(false);
  const [isSettingsActive, setSettingsActive] = React.useState<boolean>(false);
  const wallpapers: NLWallpaper[] = [greenShapeWallpaper];
  const [wallpaper, setWallpaper] = useWallpaper(greenShapeWallpaper);
  const [theme, setTheme] = useTheme(ThemeState.auto);
  const [isAudioPlayerActive, setAudioPlayerActive] = React.useState<boolean>(false);

  // Resize event handler
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 640) {
        setSidebarHidden(false); // Show sidebar when the window is larger than 640px
      } else if (window.innerWidth <= 640) {
        setSidebarHidden(true); // Hide sidebar when the window is smaller than or equal to 640px
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on mount

    // Clean up the resize event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Toggle theme based on the selected theme
  useEffect(() => {
    toggleTheme();
  }, [theme]);

  const toggleTheme = () => {
    let themeToBeSet = theme;
    if (theme === ThemeState.auto) {
      const now = new Date();
      const currentHour = now.getHours();
      const isNightTime = currentHour >= 18 || currentHour <= 5;
      themeToBeSet = isNightTime ? ThemeState.dark : ThemeState.light;
    }
    updateMetaThemeColor(themeToBeSet);
    document.documentElement.setAttribute("data-theme", themeToBeSet);
  };

  const updateMetaThemeColor = (theme: string) => {
    var themeColor = "#F0F0EE";
    if (theme === "dark") {
      themeColor = "#161616";
    }
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor?.setAttribute('content', themeColor);
    console.log(metaThemeColor);
  }

  // Provide values to be shared across the app
  const providerValue: LayoutContextProps = {
    isSidebarHidden,
    sideBarWidth,
    setSidebarHidden,
    isMailListHidden,
    mailListWidth,
    setMailListHidden,
    navigationBarHeight,
    searchNavigationBarHeight,
    isSettingsActive,
    setSettingsActive,
    wallpaper,
    setWallpaper,
    wallpapers,
    theme,
    setTheme,
    isAudioPlayerActive,
    setAudioPlayerActive,
  };

  return <LayoutContext.Provider value={providerValue}><Outlet /></LayoutContext.Provider>;
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};