import { FC, useState, useRef, useEffect } from "react";
import ParticlesBackground from "@components/common/ParticlesBackground";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { kStringConstants } from "@utils/constants/StringConstants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import emailAnimation from "@images/email_animation.json";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { PUBProfile } from "@models/Models";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { useAuth } from "@providers/AuthContext";
import { generateToast } from "@utils/managers/ToastManager";
import OTPModal from "@components/common/OTPModal";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";

export interface MagicLinkObject {
	token: string;
	is_profile_setup: string;
	email_address: string;
}

const MagicLinkView = (props) => {
	const [isAnimationComplete, setAnimationComplete] = useState(false);
	const [lastResendAttempt, setLastResendAttempt] = useState<Date | null>(null);
	const [isShowOTPModal, setIsShowOTPModal] = useState(false);
	const playerRef = useRef<LottieRefCurrentProps>(null);
	const location = useLocation();
	const navigate = useNavigate();

	const magicLinkObject: MagicLinkObject | undefined = location.state?.magicLinkObject;
	const emailAddress: string | undefined = location.state?.email;
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});

	const { setAuthUser } = useAuth();

	useEffect(() => {
		if (!emailAddress && !magicLinkObject) {
			navigate("/login", { replace: true });
			return;
		}

		if (magicLinkObject) {
			freezeAnimation();
			setAnimationComplete(true);
			signInWithBackend(magicLinkObject);
			return;
		}
	}, []);

	const resendOnClick = (e) => {
		e.preventDefault();

		if (!isEligibleForResend()) {
			generateToast({ status: "error", title: "Please wait before retrying", message: "It can take a minute or two for the magic link email to arrive. Remember to check your spam folder." });
			return;
		}

		networkManager
			.logInUser(emailAddress)
			.then((_response) => {
				setLastResendAttempt(new Date());
				recordEvent(kAnalyticsConstants.Onboarding.resentLink, { email: emailAddress ?? "" });
				generateToast({ status: "success", title: "Magic link resent", message: "Please allow a moment for the email to arrive and make sure to check your spam folder." });
			})
			.catch((error) => {
				generateToast({ status: "error", title: kStringConstants.Common.errorAlertTitle, message: kStringConstants.Common.errorAlertMessage });
			});
	};

	function isEligibleForResend(): boolean {
		if (!lastResendAttempt) {
			return true;
		}

		const elapsed: number = (new Date().getTime() - lastResendAttempt.getTime()) / 1000;
		const duration: number = Math.floor(elapsed);

		return duration > 60;
	}

	const freezeAnimation = () => {
		if (playerRef.current) {
			var frames = playerRef.current.getDuration(true);
			if (frames !== null && frames !== undefined) {
				frames = frames - 1;
				playerRef.current.goToAndStop(frames, true);
			}
		}
	};

	const signInWithBackend = (magicLinkObject: MagicLinkObject) => {
		setLoadingState({ isLoading: true, message: "Signing you in", noBackground: true });

		validateUserWithMagicLinkToken(magicLinkObject.token)
			.then(() => {
				return networkManager.getUserProfile();
			})
			.then((profile) => {
				return saveProfile(profile);
			})
			.then(() => {
				didCompleteSignIn();
			})
			.catch((error) => {
				navigate("/login", { replace: true, state: { magicLinkObject: magicLinkObject } });
				return;
			})
			.finally(() => {
				setLoadingState({ isLoading: false });
			});
	};

	const validateUserWithMagicLinkToken = (magicLinkToken: string): Promise<void> => {
		return new Promise((resolve, reject) => {
			networkManager
				.validateMagicLinkToken(magicLinkToken)
				.then(() => {
					recordEvent(kAnalyticsConstants.Onboarding.magicLinkValidated, { email: emailAddress ?? "" });
					resolve();
				})
				.catch((error) => {
					recordEvent(kAnalyticsConstants.Onboarding.magicLinkValidationError, { error: error, email: emailAddress ?? "" });
					reject(error);
				});
		});
	};

	const saveProfile = (userProfile: PUBProfile): Promise<PUBProfile> => {
		return new Promise(function (resolve, reject) {
			setAuthUser(userProfile);
			analyticsManager.analyticsCompletedSignUp(userProfile);
			resolve(userProfile);
		});
	};

	const didCompleteSignIn = () => {
		const nextPath = sessionStorage.getItem(kLocalStorageKeys.Session.nextPath);
		if (nextPath) {
			navigate(nextPath, { replace: true });
			return;
		}
		navigate("/", { replace: true });
	};

	return (
		<div>
			<LoadingView loadingState={loadingState} />
			<ParticlesBackground />
			<OTPModal isShow={isShowOTPModal} onClose={() => setIsShowOTPModal(false)} />
			<div className="flex min-h-[100dvh] flex-col justify-center overflow-hidden py-2 md:py-12">
				<div className="flex flex-col gap-10 items-center justify-center p-3">
					<div className="meco_container text-center max-w-[500px] py-[40px] md:p-[60px]">
						<div className="flex flex-col gap-2 items-center">
							<div className={`text-white font-black font-primary text-3xl ${isAnimationComplete ? "transition-opacity ease-in duration-250 opacity-100" : "opacity-0"}`}>Check your email!</div>
							<div className={`text-white/50 font-regular font-primary text-lg ${isAnimationComplete ? "transition-opacity ease-in duration-250 opacity-100" : "opacity-0"}`}>Confirm your email address by tapping the link in the email we just sent you.</div>
							<Lottie className="w-[280px]" animationData={emailAnimation} lottieRef={playerRef} loop={false} onComplete={() => setAnimationComplete(true)} />

							<div className={`text-white font-regular font-primary text-base  leading-loose"  ${isAnimationComplete ? "transition-opacity ease-in delay-500 duration-250 opacity-100" : "opacity-0"}`}>
								Didn’t receive an email?
								<br />
								Check your spam folder,{" "}
								<button onClick={resendOnClick} className="underline inline text-success-green hover:text-success-green transition ease-in-out">
									resend email
								</button>{" "}
								or{" "}
								<a href="mailto:team@meco.app" className="text-success-green hover:text-success-green transition ease-in-out">
									{" "}
									contact us
								</a>
								.<br />
								Alternatively, enter your{" "}
								<button onClick={() => setIsShowOTPModal(true)} className="underline inline text-success-green hover:text-success-green transition ease-in-out">
									{" "}
									sign-in code
								</button>
								.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MagicLinkView;
