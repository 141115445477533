import SidebarWrapper from "@components/common/Sidebar/SidebarWrapper";
import { useLayout } from "@providers/LayoutContext";
import { SidebarProvider } from "@providers/SidebarContext";
import { Outlet } from "react-router-dom";
import { ReactComponent as MecoLogo } from "@images/meco_logo.svg";

const HomeLayout = () => {
	const { isSidebarHidden, sideBarWidth, wallpaper } = useLayout();

	return (
		<>
			<SidebarProvider>
				<img className="h-full min-h-screen fixed w-full bottom-0 left-0 z-[-2] object-cover" src={wallpaper.image} />
				<div className={`top-0 left-0 h-full ${wallpaper.containerColor}`} style={isSidebarHidden ? { position: "fixed", width: "100%" } : { width: "calc(100% - " + sideBarWidth + "px)", left: sideBarWidth + "px", position: "fixed" }} />
				<SidebarWrapper />

				<div className="flex flex-row">
					<div style={isSidebarHidden ? { position: "relative", width: "100%" } : { position: "relative" }} className="sm:left-[300px] left-0 sm:w-[calc(100%-300px)] w-full">
						<Outlet />
						<div className="w-full flex flex-col justify-center items-center p-3 py-[3rem] text-primary font-regular font-primary text-xs gap-3">
							<a href="https://www.meco.app" target="_blank"><MecoLogo className="fill-primary h-[14px]" /></a>
							<p>© {new Date().getFullYear()}&nbsp; Meco - All rights reserved</p>
							<div className="font-medium text-center leading-5">
								<a href="mailto:team@meco.app" className="no-underline hover:text-success-green duration-300 transition ease-in-out">
									Get In Touch
								</a>{" "}
								&middot;{" "}
								<a href="https://x.com/meco_app?ref_src=twsrc%5Etfw" target="_blank" className="no-underline hover:text-success-green duration-300 transition ease-in-out">
									Follow us on X
								</a>
								<br />
								<a href="https://www.meco.app/privacy" target="_blank" className="no-underline hover:text-success-green duration-300 transition ease-in-out">
									Privacy Policy
								</a>{" "}
								&middot;{" "}
								<a href="https://www.meco.app/terms" target="_blank" className="no-underline hover:text-success-green duration-300 transition ease-in-out">
									Terms &amp; Conditions
								</a>
							</div>
						</div>
					</div>
				</div>
			</SidebarProvider>
		</>
	);
};

export default HomeLayout;
