import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./MonthPicker.module.scss";
import { ReactComponent as CalendarLeftArrow } from "@images/calendar_left_arrow.svg";
import { ReactComponent as CalendarRightArrow } from "@images/calendar_right_arrow.svg";

export function MonthPicker(props) {
	const [month, setMonth] = useState(props.selected.month ? props.selected.month - 1 : new Date().getMonth());
	const [year, setYear] = useState(props.selected.year ?? new Date().getFullYear());

	const getCurrentMonthAndYear = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		const currentYear = currentDate.getFullYear();
		return [currentMonth, currentYear];
	};

	const setActiveMonthBgColor = (r, color) => {
		r.style.setProperty("--month-active-bg-color", color);
	};

	useEffect(() => {
		const r = document.querySelector(":root");
		if (props.bgColorMonthActive) {
			setActiveMonthBgColor(r, props.bgColorMonthActive);
		}
		if (props.bgColorMonthHover) {
			r.style.setProperty("--month-hover-bg-color", props.bgColorMonthHover);
		}
		if (props.borderRadiusMonth) {
			r.style.setProperty("--month-border-radius", props.borderRadiusMonth);
		}
		if (props.bgColorPicker) {
			r.style.setProperty("--picker-bg-color", props.bgColorPicker);
		}
		if (props.textColor) {
			r.style.setProperty("--text-color", props.textColor);
		}
		if (props.size == "small") {
			r.style.setProperty("--picker-padding", "1rem");
			r.style.setProperty("--year-display-margin-top", "0.5rem");
			r.style.setProperty("--year-display-margin-bottom", "0.5rem");
			r.style.setProperty("--month-select-padding", "0.5rem");
		}
	}, []);

	const changeYear = (year) => {
		setYear(year);
	};

	const getMonthNames = (locale = "en", format = "short") => {
		const formatter = new Intl.DateTimeFormat(locale, {
			month: format,
			timeZone: "UTC",
		});
		const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
			const mm = month < 10 ? `0${month}` : month;
			return new Date(`2023-${mm}-01T00:00:00+00:00`);
		});
		return months.map((date) => formatter.format(date));
	};

	const changeMonth = (month) => {
		setMonth(month);
		props.setIsOpen(false);
		props.onChange({
			month: month + 1,
			year: year,
			monthName: new Date(year, month).toLocaleString(props.lang ? props.lang : "en", {
				month: "long",
			}),
			monthShortName: new Date(year, month).toLocaleString(props.lang ? props.lang : "en", {
				month: "short",
			}),
		});
	};

	const isBelowMaxYear = () => {
		
		if (props.selected.minDate) {
			const [minYear, minMonth] = props.selected.minDate.split('-');
			return year > minYear;
		}

		return false
	}

	const isAboveMinYear = () => {
		return year < getCurrentMonthAndYear()[1];
	}

	const isBelowMaxMonth = (monthIndex) => {
		
		const [currentMonth, currentYear] = getCurrentMonthAndYear();

		if (year == currentYear) {
			return monthIndex <= (currentMonth - 1)
		}
		
		return year <= currentYear;
	}

	const isAboveMinMonth = (monthIndex) => {

		if (props.selected.minDate) {
			const [minYear, minMonth] = props.selected.minDate.split('-');

			if (year == minYear) {
				return monthIndex >= (minMonth - 1);
			}

			return year >= minYear;
		}

		return false

	}

	return (
		<div className={styles.pickerContainer} ref={props.pickerRef}>
			<div className={styles.yearContainer}>
				<button disabled={!(isBelowMaxYear())} aria-label="Previous Year" onClick={(e) => changeYear(year - 1)} style={{opacity: isBelowMaxYear() ? 1.0 : 0.25 }} >
					<CalendarLeftArrow className="fill-primary h-[24px]"/>
				</button>
				<span aria-description="Year selected" className={styles.bold1}>
					{year}
				</span>
				<button disabled={!(isAboveMinYear())} aria-label="Next Year" onClick={(e) => changeYear(year + 1)} style={{opacity: isAboveMinYear() ? 1.0 : 0.25 }}>
					<CalendarRightArrow className="fill-primary h-[24px]"/>
				</button>
			</div>
			<div className={styles.monthsContainer}>
				{getMonthNames(props.lang).map((monthName, index) => {
					return (
						<button disabled={!(isBelowMaxMonth(index) && isAboveMinMonth(index))} key={index} className={`${styles.month} ${index == month && (props.selected.year ?? new Date().getFullYear()) == year ? styles.active : null}`} style={{opacity: isBelowMaxMonth(index) && isAboveMinMonth(index) ? 1.0 : 0.25 }} onClick={(e) => changeMonth(index)}>
							{monthName}
						</button>
					);
				})}
			</div>
		</div>
	);
}
