export enum CampaignType {
	Affiliate = "affiliate",
	Engaged = "affiliate_engaged",
}

export enum OverviewStatType {
	Table = "table",
	Metric = "metric",
	Bar = "bar",
	Line = "line",
	Stack = "stack",
}

export enum ThemeState {
	auto = "auto",
	dark = "dark",
	light = "light",
}