import { ReactComponent as ExpandArrow } from "@images/expand_arrow.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { ReactComponent as MecoLogo } from "@images/meco_publishers_logo.svg";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSidebar } from "@providers/SidebarContext";
import { useLayout } from "@providers/LayoutContext";
import { SubMenuItem, LinkItem, MenuItem } from "./SidebarWrapper";
import { useAuth } from "@providers/AuthContext";
import { Banknote, ChartNoAxesCombined } from "lucide-react";
import { kAppConstants } from "@utils/constants/AppConstants";
import { ReactComponent as SettingsIcon } from "@images/settings_icon.svg";
import NewsletterSwitcher from "../NewsletterSwitcher";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const DefaultSidebar = () => {
	const { setSidebarHidden, navigationBarHeight, setSettingsActive } = useLayout();
	const { activeMenuItem, setActiveMenuItem } = useSidebar();
	const [subMenuExpandStates, setSubMenuExpandStates] = useState<{ [key: string]: boolean }>({});
	const { authUser, activeNewsletter, setActiveNewsletter, earnActive, growActive } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		setActiveMenuItem(undefined);
		for (let menuItem of menuItems) {
			if (location.pathname === menuItem.link.link) {
				setActiveMenuItem(menuItem);
				break;
			}
			if (location.pathname === "/earn/join" && menuItem.title === "Earn with Meco") {
				setActiveMenuItem(menuItem);
				break;
			}
			if (location.pathname === "/grow/join" && menuItem.title === "Grow with Meco") {
				setActiveMenuItem(menuItem);
				break;
			}
		}
	}, [location]);

	const getSubMenuExpandState = (initial: boolean, menuItemTitle: string): boolean => {
		if (subMenuExpandStates[menuItemTitle] !== undefined) {
			return subMenuExpandStates[menuItemTitle];
		} else {
			setSubMenuExpandStates((prevStates) => ({
				...prevStates,
				[menuItemTitle]: initial,
			}));
			return initial;
		}
	};

	const toggleSubMenuExpandState = (menuItemTitle: string) => {
		setSubMenuExpandStates((prevStates) => ({
			...prevStates,
			[menuItemTitle]: !prevStates[menuItemTitle],
		}));
	};

	const getEarnItems = (): SubMenuItem[] | undefined => {
		const payouts: SubMenuItem = { key: `payouts`, title: "Payouts", action: () => goToLink("/earn/payouts"), isActive: location.pathname.includes("/earn/payouts") };
		const payoutSettings: SubMenuItem = { key: `payoutSettings`, title: "Payout Settings", action: () => goToLink("/earn?payoutSettings=true"), isActive: false };

		return earnActive ? [payouts, payoutSettings] : [];
	};

	const getGrowItems = (): SubMenuItem[] | undefined => {
		const subscribers: SubMenuItem = { key: `subscribers`, title: "Your Subscribers", action: () => goToLink("/grow/subscribers"), isActive: location.pathname.includes("/grow/subscribers") };
		const paymentSettings: SubMenuItem = {
			key: `paymentSettings`,
			title: "Payment Settings",
			action: () => {
				recordEvent(kAnalyticsConstants.Grow.paymentSettingsTapped);
				window.open(kAppConstants.stripeCustomerPortalUrl + encodeURI(authUser!.email), "_blank");
			},
			isActive: false,
		};
		const alerts: SubMenuItem = { key: `alerts`, title: "Alerts", action: () => goToLink("/grow/alerts"), isActive: location.pathname.includes("/grow/alerts") };
		const integrations: SubMenuItem = { key: `integrations`, title: "Integrations", action: () => goToLink("/grow/integrations"), isActive: location.pathname.includes("/grow/integrations") };

		return growActive ? [subscribers, paymentSettings, alerts, integrations] : [];
	};

	var earnLinkItem: LinkItem = { link: "/earn", isParentLink: false };
	var growLinkItem: LinkItem = { link: "/grow", isParentLink: false };

	const earnTitle = "Earn with Meco";
	const growTitle = "Grow with Meco";

	var earnItem: MenuItem = { icon: Banknote, title: earnTitle, subMenu: getEarnItems(), subMenuExpandState: getSubMenuExpandState(true, earnTitle), subMenuLoadingState: false, link: earnLinkItem };
	var growItem: MenuItem = { icon: ChartNoAxesCombined, title: growTitle, subMenu: getGrowItems(), subMenuExpandState: getSubMenuExpandState(true, growTitle), subMenuLoadingState: false, link: growLinkItem };

	var menuItems: MenuItem[] = [earnItem, growItem];

	useEffect(() => {
		const tempMenuItems = [...menuItems];
		for (let menuItem of tempMenuItems) {
			if (menuItem.link.isParentLink && location.pathname.includes(menuItem.link.link)) {
				setActiveMenuItem(menuItem);
				break;
			}
			if (location.pathname === menuItem.link.link) {
				setActiveMenuItem(menuItem);
				break;
			}
		}
	}, [location]);

	const goToLink = (link: string) => {
		if (window.innerWidth <= 640) {
			setSidebarHidden(true);
		}
		navigate(link);
	};

	const getNewsletterSwitcherOptions = (): string[] => {
		return [...authUser!.newsletters.map((x) => x.name!)];
	};

	const newsletterSwitched = (selectedOption: string | null) => {
		if (!selectedOption) {
			return;
		}
		recordEvent(kAnalyticsConstants.App.newsletterSwitched, { newsletter_name: selectedOption });
		setActiveNewsletter(authUser!.newsletters.find((x) => x.name === selectedOption));
		window.location.reload();
	};

	return (
		<div className="flex flex-col items-center flex-shrink-0 flex-grow-0 overflow-y-auto basis-full sm:basis-[300px]">
			<div
				className="sticky top-0 bg-contrast/5
			 dark:bg-transparent backdrop-blur-lg flex flex-row justify-center w-full items-center p-3 border-b border-primary-100"
				style={{ height: navigationBarHeight + "px" }}>
				<div className="flex flex-row justify-between items-center w-full">
					<MecoLogo className="fill-primary !h-[16px] cursor-pointer" onClick={() => goToLink("/")} />
					<div className="flex flex-row">
						<IconButton Icon={SettingsIcon} className="sm:-mr-2.5 mr-0" onClick={() => setSettingsActive(true)} />
						<IconButton Icon={ExitIcon} forcedIconSize={18} className="-mr-2.5 sm:hidden" onClick={() => setSidebarHidden(true)} />
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-2 w-full px-3 py-3">
				{menuItems.map((menuItem) => {
					return (
						<div key={menuItem.title} className="w-full flex flex-col gap-2 items-start">
							<button className={`${menuItem.title === activeMenuItem?.title ? "bg-primary-100 dark:bg-primary-200 border-1 border-primary-100" : null} w-full rounded-xl flex flex-row items-center gap-3 py-2 text-base font-primary font-bold text-primary h-[45px] hover:bg-primary-100 hover:border-1 hover:border-primary-100 transition-[background] ease-in-out duration-300`} onClick={() => goToLink(menuItem.link.link)}>
								<div className="w-full flex flex-row justify-between items-center">
									<div className="flex flex-row items-center">
										<div className="w-[42px]">
											<menuItem.icon className="!w-[20px] !h-[20px] m-auto" />
										</div>
										<div>{menuItem.title}</div>
									</div>
									{menuItem.subMenuLoadingState ? (
										<LoadingSpinner className="w-[20px] text-primary-200 animate-spin fill-primary mr-2" />
									) : (
										menuItem.subMenu &&
										menuItem.subMenu.length > 0 && (
											<button
												className={`w-[20px] h-[20px] mr-2.5`}
												onClick={(e) => {
													e.stopPropagation();
													toggleSubMenuExpandState(menuItem.title);
												}}>
												<ExpandArrow className={`fill-primary-500 transform-rotate duration-300 ease-in-out hover:fill-primary ${menuItem.subMenuExpandState ? "rotate-0" : "-rotate-180"}`} />
											</button>
										)
									)}
								</div>
							</button>
							{menuItem.subMenu && menuItem.subMenuExpandState && (
								<div className="flex flex-row w-full items-stretch gap-2">
									<div className="w-[25px] flex-shrink-0">
										<div className="border-r-[3px] h-full border-primary-200"></div>
									</div>
									<div className="w-[calc(100%-25px)] flex flex-col">
										{menuItem.subMenu.map((subMenuItem) => {
											return (
												<button key={subMenuItem.key} className={`${subMenuItem.isActive ? "bg-primary-100 dark:bg-primary-200 border-1 border-primary-100 text-primary" : "text-primary-500"} w-full rounded-xl flex flex-col gap-3 px-3 py-1 text-base font-primary font-medium  hover:text-primary h-[36px] hover:bg-primary-100 hover:border-1 hover:border-primary-100 transition-all ease-in-out duration-300 justify-center truncate overflow-hidden text-ellipsis max-w-[230px] mt-[3px]`} onClick={subMenuItem.action}>
													<div className="text-ellipsis overflow-hidden w-[calc(100%-25px)] text-left">{subMenuItem.title}</div>
												</button>
											);
										})}
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
			<div className="absolute bottom-2 w-full pb-3 flex flex-col gap-3">
				<div className="px-3">
					<NewsletterSwitcher imageUrl={activeNewsletter?.logo} options={getNewsletterSwitcherOptions()} defaultValue={activeNewsletter?.name} setSelectedOption={(selectedOption) => newsletterSwitched(selectedOption)} />
				</div>
			</div>
		</div>
	);
};

export default DefaultSidebar;
