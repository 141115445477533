export const kLocalStorageKeys = {
	App: {

	},
	AppSettings: {
		theme: "theme",
		activeWallpaper: "active_wallpaper",
	},
	Session: {
		utmCampaign: "utm_campaign",
		nextPath: "next_path",
	},
	User: {
		activeNewsletterId: "active_newsletter_id",
	}
};
