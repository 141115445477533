import { useState, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import OtpInput from "react-otp-input";
import { validateMagicCode } from "@utils/managers/networking/NetworkManager";
import { useNavigate } from "react-router-dom";
import { generateToast } from "@utils/managers/ToastManager";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

interface OTPModalProps {
	isShow: boolean;
	onClose: () => void;
}

const OTPModal: FC<OTPModalProps> = ({ isShow, onClose }) => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [otp, setOtp] = useState<string>("");
	const [isLoading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	useHotkeys("Escape", () => onModalClose());

	const onModalClose = () => {
		setOtp("");
		setLoading(false);
		onClose();
	};

	const handlePaste: React.ClipboardEventHandler = (event) => {
		const data = event.clipboardData.getData("text");
		const regex = /^\d{6}$/;
		if (!regex.test(data)) return;
		setOtp(data);
		codeOnClick(data);
	};

	const codeOnClick = (forceOtp?: string) => {
		setLoading(true);
		recordEvent(kAnalyticsConstants.Onboarding.magicCodeEntered);
		var tempOtp = otp;
		if (forceOtp) {
			tempOtp = forceOtp;
		}
		validateMagicCode(tempOtp)
			.then((response) => {
				recordEvent(kAnalyticsConstants.Onboarding.magicCodeValidated);
				if (response["magic_link"]) {
					const url = new URL(response["magic_link"]);
					const pathname = url.pathname;
					navigate(pathname);
					return;
				}
				generateToast({ status: "error", title: "Invalid code", message: "Make sure you copy the 6 digit code at the bottom of the magic link email we previously sent." });
			})
			.catch((error) => {
				setLoading(false);
				recordEvent(kAnalyticsConstants.Onboarding.magicCodeError, { error: error.message });
				generateToast({ status: "error", title: "Invalid code", message: "Make sure you copy the 6 digit code at the bottom of the magic link email we previously sent." });
			});
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="max-w-[400px] transform overflow-hidden rounded-2xl bg-black border border-white/10 text-left align-middle shadow-xl transition-all p-4">
								<div className="flex flex-col gap-2">
									<div className="w-full flex flex-row justify-between items-center pb-2">
										<div className="text-white font-medium font-primary text-xl">Enter sign-in code</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5 hover:bg-white/20" iconClassName="fill-white" onClick={() => onModalClose()} />
									</div>
									<div className="flex flex-col gap-4 text-center h-full w-full">
										<div className="text-white font-regular font-primary text-sm">There should be a 6 digit code at the bottom of your magic link email, add the code below to continue.</div>
										<form onSubmit={() => codeOnClick()}>
											<div className="flex flex-col gap-4 flex-grow">
												<OtpInput
													value={otp}
													onChange={setOtp}
													numInputs={6}
													renderSeparator={<span style={{ width: "8px" }}>&nbsp</span>}
													renderInput={(props) => <input {...props} className="font-primary text-black " />}
													inputType={"tel"}
													onPaste={handlePaste}
													shouldAutoFocus={true}
													inputStyle={{
														border: "1px solid transparent",
														borderRadius: "12px",
														fontSize: "30px",
														caretColor: "green",
														width: "100%",
														margin: "0 auto"
													}}
												/>
												<button type="submit" disabled={otp.length !== 6 || isLoading} className={`bg-success-green py-2 font-primary text-lg text-white rounded-xl shadow-sm w-full h-[44px] ${otp.length === 6 ? "opacity-100" : "opacity-50"}`} onClick={() => codeOnClick()}>
													{isLoading ? <LoadingSpinner className="m-auto h-[20px] text-white/20 animate-spin fill-white" /> : <div>Continue</div>}
												</button>
											</div>
										</form>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default OTPModal;
