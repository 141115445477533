import { useAuth } from "@providers/AuthContext";
import TitleHeader from "@components/common/TitleHeader";
import { useEffect, useRef, useState } from "react";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import SectionLayout from "@layouts/SectionLayout";
import { Switch } from "@headlessui/react";
import ListboxView from "@components/common/ListboxView";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Icon } from "@tremor/react";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const GrowAlerts = () => {
	const { authUser } = useAuth();
	const weekDays = ["Off", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const [selectedWeekDay, setSelectedWeekDay] = useState<number>(authUser!.alerts.weekly_summary ?? 0);
	const [dailyAlert, setDailyAlert] = useState<boolean>(authUser!.alerts.daily_summary ?? false);
	const [instantAlert, setInstantAlert] = useState<boolean>(authUser!.alerts.instant ?? false);

	const hasMounted = useRef(false);

	useEffect(() => {
		analyticsManager.recordEvent(kAnalyticsConstants.Grow.alertsViewed);
	}, []);

	useEffect(() => {
		if (hasMounted.current) {
			syncAlertsWithBackend(instantAlert, dailyAlert, selectedWeekDay);
		} else {
			hasMounted.current = true;
		}
	}, [instantAlert, dailyAlert, selectedWeekDay]);

	const syncAlertsWithBackend = (copyInstantAlert, dayAlert, weekAlert) => {
		let weeklySummary = weekAlert == 0 ? null : weekAlert;
		let payload = { weekly_summary: weeklySummary, daily_summary: dayAlert, instant: copyInstantAlert };
		networkManager.postAlerts(payload);
		analyticsManager.recordEvent(kAnalyticsConstants.Grow.alertsChanged, { weekly_summary_status: weeklySummary, daily_summary_status: dayAlert, instant_status: copyInstantAlert });
	};

	return (
		<>
			<TitleHeader title="Grow with Meco" />
			<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12 min-h-[100vh]">
				<div className="flex flex-col gap-5 m-auto">
					<SectionLayout sectionTitle="Alerts">
						<div className="w-full flex flex-col gap-[50px]">
							{selectedWeekDay == 0 && dailyAlert == false && instantAlert == false && (
								<div className="w-full border-[1px] bg-red-600 border-card rounded-[10px] p-[20px] font-regular font-primary text-base text-white flex flex-row items-center gap-3">
									<Icon icon={ExclamationTriangleIcon} color="yellow" variant="light" size="sm" />
									<div>[Important] We highly recommend at least 1 active alert so that we can inform you of any new subscribers to your newsletter. Without alerts you may miss out on new readers.</div>
								</div>
							)}
							<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-between">
								<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center w-full">
									<div className="text-primary font-medium font-primary text-lg">Instant alerts for new subscribers</div>
									<div className="text-primary-500 font-regular font-primary text-sm">Get emailed instantly for each of your subscriber for your newsletter.</div>
								</div>
								<div className="flex flex-col gap-3 items-center w-full sm:w-auto">
									<Switch checked={instantAlert} onChange={setInstantAlert} className={`${instantAlert ? "bg-success-green" : "bg-primary"} relative inline-flex h-[30px] w-[55px] items-center rounded-full`}>
										<span className={`${instantAlert ? "translate-x-[26px]" : "translate-x-[2px]"} inline-block h-[28px] w-[28px] transform rounded-full bg-white transition`} />
									</Switch>
								</div>
							</div>
							<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-between">
								<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center w-full">
									<div className="text-primary font-medium font-primary text-lg">Daily summary of new subscribers</div>
									<div className="text-primary-500 font-regular font-primary text-sm">A daily email (sent at 7am local time) to inform you of the new subscribers you've had for your newsletter from the previous day. You won’t get an email if there were no new subscribers.</div>
								</div>
								<div className="flex flex-col gap-3 items-center w-full sm:w-auto">
									<Switch checked={dailyAlert} onChange={setDailyAlert} className={`${dailyAlert ? "bg-success-green" : "bg-primary"} relative inline-flex h-[30px] w-[55px] items-center rounded-full`}>
										<span className={`${dailyAlert ? "translate-x-[26px]" : "translate-x-[2px]"} inline-block h-[28px] w-[28px] transform rounded-full bg-white transition`} />
									</Switch>
								</div>
							</div>
							<div className="flex flex-col md:flex-row gap-4 md:gap-5 items-center justify-between">
								<div className="flex flex-col gap-1 md:items-start items-center md:text-left text-center w-full">
									<div className="text-primary font-medium font-primary text-lg">Weekly summary of new subscribers</div>
									<div className="text-primary-500 font-regular font-primary text-sm">A weekly email (sent at 7am local time on a day of your choosing) informing you of any new subscribers for your newsletter from the previous week. You won’t get an email if there were no new subscribers.</div>
								</div>
								<div className="flex flex-col gap-3 items-center w-full sm:w-[200px]">
									<ListboxView selected={weekDays[selectedWeekDay]} setSelected={(selectedString) => setSelectedWeekDay(weekDays.indexOf(selectedString))} options={weekDays} />
								</div>
							</div>
						</div>
					</SectionLayout>
				</div>
			</div>
		</>
	);
};

export default GrowAlerts;
