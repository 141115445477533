import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { PUBNewsletter, PUBProfile } from "@models/Models";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { Outlet } from "react-router-dom";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";

interface AuthContextProps {
	authUser: PUBProfile | null | undefined;
	setAuthUser: (profile: PUBProfile) => void;
	activeNewsletter: PUBNewsletter | undefined;
	setActiveNewsletter: (newsletter: PUBNewsletter | undefined) => void;
	earnActive: boolean | undefined;
	setEarnActive: (earnActive: boolean) => void;
	growActive: boolean | undefined;
	setGrowActive: (growActive: boolean) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = () => {
	const [authUser, setAuthUser] = React.useState<PUBProfile | null | undefined>(undefined);
	const [activeNewsletter, setActiveNewsletter] = React.useState<PUBNewsletter | undefined>(undefined);
	const [earnActive, setEarnActive] = React.useState<boolean | undefined>(undefined);
	const [growActive, setGrowActive] = React.useState<boolean | undefined>(undefined);

	useEffect(() => {
		if (authUser) {
			return;
		}

		networkManager
			.getUserProfile(false)
			.then((profile) => {
				setAuthUser(profile);
			})
			.catch((_error) => {
				setAuthUser(null);
			});
	}, []);

	useEffect(() => {
		if (!authUser) {
			return;
		}

		let newsletterArray = authUser.newsletters;

		if (newsletterArray.length === 0) {
			setAuthUser(null);
			return;
		}

		let savedActiveNewsletterId = localStorage.getItem(kLocalStorageKeys.User.activeNewsletterId);
		var tempActiveNewsletter: PUBNewsletter | undefined; 
		if (savedActiveNewsletterId && newsletterArray.find((x) => x.id ===  parseInt(savedActiveNewsletterId))) {
			tempActiveNewsletter = newsletterArray.find((x) => x.id ===  parseInt(savedActiveNewsletterId));
		} else {
			tempActiveNewsletter = authUser.newsletters[0]; 
		}
		determineEarnStatus(tempActiveNewsletter);
		setActiveNewsletter(tempActiveNewsletter);
	}, [authUser]);

	useEffect(() => {
		if (!activeNewsletter) {
			return;
		}
		localStorage.setItem(kLocalStorageKeys.User.activeNewsletterId, activeNewsletter.id.toString());
	}, [activeNewsletter]);

	function determineEarnStatus(selectedNewsletter) {
		//Determine Earn
		const earnActiveValue = selectedNewsletter?.is_referral_active;
		const earnActive = Boolean(earnActiveValue);
		setEarnActive(earnActive);

		//Determine Grow Affilaite
		const growAffiliateActiveValue = selectedNewsletter?.is_affiliate_active;
		const growAffiliateActive = Boolean(growAffiliateActiveValue);
		setGrowActive(growAffiliateActive);
	}

	const providerValue: AuthContextProps = {
		authUser,
		setAuthUser,
		activeNewsletter,
		setActiveNewsletter,
		earnActive,
		setEarnActive,
		growActive,
		setGrowActive,
	};

	return (
		<AuthContext.Provider value={providerValue}>
			<Outlet />
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
