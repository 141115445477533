import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import mixpanel from "mixpanel-browser";
import { kAppConstants } from "@utils/constants/AppConstants";

const isProd = kAppConstants.appStage === "production";

mixpanel.init(kAppConstants.mixpanelToken, { debug: !isProd });

Sentry.init({
	dsn: "https://41a433d50822397354a3a900d1e0b10b@o1210212.ingest.us.sentry.io/4508693894266880",
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.5, // Should not use 1.0 in production
	enabled: isProd,
	environment: kAppConstants.appStage,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
