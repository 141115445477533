import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { MenuItem, SubMenuItem } from "@components/common/Sidebar/SidebarWrapper";

interface SidebarContextProps {
	activeMenuItem: MenuItem | undefined,
    setActiveMenuItem: (activeMenuItem?: MenuItem) => void;
    activeSubMenuItem: SubMenuItem | undefined,
    setActiveSubMenuItem: (activeSubMenuItem: SubMenuItem) => void;
    isShowExpand: boolean,
    setShowExpand: (isShowExpand: boolean) => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [activeMenuItem, setActiveMenuItem] = React.useState<MenuItem | undefined>(undefined);
    const [activeSubMenuItem, setActiveSubMenuItem] = React.useState<SubMenuItem | undefined>(undefined);
    const [isShowExpand, setShowExpand] = React.useState<boolean>(true);

	const providerValue: SidebarContextProps = {
		activeMenuItem,
        setActiveMenuItem,
        activeSubMenuItem,
        setActiveSubMenuItem,
        isShowExpand,
        setShowExpand,
	};

	return <SidebarContext.Provider value={providerValue}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => {
	const context = useContext(SidebarContext);
	if (!context) {
		throw new Error("useSidebar must be used within an SidebarProvider");
	}
	return context;
};
