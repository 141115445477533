import React, { useState, useEffect, FC } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../common/IconButton";
import { useAuth } from "@providers/AuthContext";
import { useHotkeys } from "react-hotkeys-hook";
import { Description, Dialog, DialogBackdrop, DialogPanel, Field, Input, Label } from "@headlessui/react";
import ListboxView from "@components/common/ListboxView";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { useSearchParams } from "react-router-dom";
import { generateToast } from "@utils/managers/ToastManager";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

interface EarnPayoutSettingsModalProps {
	isShow: boolean;
	onClose: () => void;
	onUpdatePayoutDetails: () => void;
}

const EarnPayoutSettingsModal: FC<EarnPayoutSettingsModalProps> = ({ isShow, onClose, onUpdatePayoutDetails }) => {
	const { activeNewsletter } = useAuth();
	const [isValidEmail, setValidEmail] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [payoutModel, setPayoutModel] = useState<Record<string, string>>({
		wise_email_address: "",
		wise_account_type: "",
		wise_currency: "",
	});
	const [searchParams, setSearchParams] = useSearchParams();

	const accountTypes = ["Person", "Business"];

	const currencyPlaceholder = "Select your local Wise currency";
	const accountTypePlaceholder = "Select Wise account type";

	const currenciesWithEmojis = [currencyPlaceholder, "🇦🇪 AED", "🇦🇺 AUD", "🇧🇩 BDT", "🇧🇬 BGN", "🇧🇷 BRL", "🇧🇼 BWP", "🇨🇦 CAD", "🇨🇭 CHF", "🇨🇱 CLP", "🇨🇳 CNY", "🇨🇴 COP", "🇨🇷 CRC", "🇨🇿 CZK", "🇩🇰 DKK", "🇪🇬 EGP", "🇪🇺 EUR", "🇬🇧 GBP", "🇬🇪 GEL", "🇬🇭 GHS", "🇭🇰 HKD", "🇭🇺 HUF", "🇮🇩 IDR", "🇮🇱 ILS", "🇮🇳 INR", "🇯🇵 JPY", "🇰🇪 KES", "🇰🇷 KRW", "🇱🇰 LKR", "🇲🇦 MAD", "🇲🇽 MXN", "🇲🇾 MYR", "🇳🇬 NGN", "🇳🇴 NOK", "🇳🇵 NPR", "🇳🇿 NZD", "🇵🇭 PHP", "🇵🇰 PKR", "🇵🇱 PLN", "🇷🇴 RON", "🇸🇪 SEK", "🇸🇬 SGD", "🇹🇭 THB", "🇹🇷 TRY", "🇹🇿 TZS", "🇺🇬 UGX", "🇺🇦 UAH", "🇺🇸 USD", "🇺🇾 UYU", "🇻🇳 VND", "🇿🇦 ZAR", "🇿🇲 ZMW"];

	useHotkeys("Escape", () => onModalClose());

	const getCurrencyWithEmoji = (currency: string | undefined) => {
		if (!currency) {
			return currencyPlaceholder;
		}

		for (let currencyWithEmoji of currenciesWithEmojis) {
			if (currencyWithEmoji.split(" ")[1] == currency) {
				return currencyWithEmoji;
			}
		}
	};

	useEffect(() => {
		if (isShow) {
			setPayoutModel({
				wise_email_address: activeNewsletter!.wise_email ?? "",
				wise_account_type: activeNewsletter!.wise_account_type ?? "",
				wise_currency: getCurrencyWithEmoji(activeNewsletter!.wise_currency) ?? "",
			});
			setValidEmail(false);
			setIsLoading(false);
			recordEvent(kAnalyticsConstants.Earn.referralProgramJoinViewed, { newsletter_id: activeNewsletter!.id.toString() });
		}

		if (!isShow) {
			setTimeout(() => {
				removeParam();
			}, 451);
		}
	}, [isShow]);

	const removeParam = () => {
		if (searchParams.has("payoutSettings")) {
			const token = searchParams.get("payoutSettings");
			if (token) {
				searchParams.delete("payoutSettings");
				setSearchParams(searchParams);
			}
		}
	};

	const onModalClose = () => {
		onClose();
	};

	const inputOnChange = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
		const fieldValue: string = e.currentTarget.value;
		const fieldId: string = e.currentTarget.id;

		const updatedModel = {
			...payoutModel,
			[fieldId]: fieldValue,
		};

		const isEmailValid = validateEmailAddress(updatedModel.wise_email_address);
		const areAllFieldsFilled = Object.values(updatedModel).every((value) => value.trim() !== "");

		setPayoutModel(updatedModel);
		setIsFormValid(isEmailValid && areAllFieldsFilled);
	};

	const validateEmailAddress = (emailAddressString): boolean => {
		const isValid = checkEmail(emailAddressString);
		setValidEmail(isValid);
		return isValid;
	};

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	const handleCurrencyChange = (currencyWithEmoji) => {
		var currency = currencyWithEmoji;

		if (currencyWithEmoji == currencyPlaceholder) {
			currency = "";
		}

		const updatedModel = {
			...payoutModel,
			wise_currency: currency,
		};

		const isEmailValid = validateEmailAddress(payoutModel.wise_email_address);
		const areAllFieldsFilled = Object.values(updatedModel).every((value) => value.trim() !== "");

		setPayoutModel(updatedModel);
		setIsFormValid(isEmailValid && areAllFieldsFilled);
	};

	const handleAccountTypeChange = (accountType) => {
		var tempAccountType = accountType;

		if (accountType == accountTypePlaceholder) {
			tempAccountType = "";
		}

		const updatedModel = {
			...payoutModel,
			wise_account_type: tempAccountType,
		};

		const isEmailValid = validateEmailAddress(payoutModel.wise_email_address);
		const areAllFieldsFilled = Object.values(updatedModel).every((value) => value.trim() !== "");

		setPayoutModel(updatedModel);
		setIsFormValid(isEmailValid && areAllFieldsFilled);
	};

	const onSubmitClick = (e) => {
		e.preventDefault();

		if (!isFormValid || !isValidEmail) {
			setIsLoading(false);
			return;
		}

		const currencyCode = payoutModel.wise_currency.split(" ")[1];

		setIsLoading(true);

		networkManager
			.postWiseData(activeNewsletter!.id.toString(), payoutModel.wise_email_address, currencyCode, payoutModel.wise_account_type)
			.then(() => {
				recordEvent(kAnalyticsConstants.Earn.referralProgramPaymentInfo, { newsletter_id: activeNewsletter!.id.toString() });
				removeParam();
				generateToast({ status: "success", title: "Payout settings updated" });
				setTimeout(() => {
					setIsLoading(false);
					onUpdatePayoutDetails();
				}, 1000);
			})
			.catch((error) => {
				recordEvent(kAnalyticsConstants.Earn.referralProgramPaymenInforError, { newsletter_id: activeNewsletter!.id.toString(), error: error?.message ?? "" });
				setIsLoading(false);
				generateToast({ status: "error", title: "Something went wrong.", message: "Please try again later or contact us." });
			});
	};

	return (
		<>
			<Dialog open={isShow} onClose={() => onClose()} transition className="relative z-40 transition duration-300 ease-out data-[closed]:opacity-0">
				<DialogBackdrop className="fixed inset-0 bg-black/80" />
				<div className="fixed inset-0 flex w-screen items-center justify-center p-2 sm:p-4">
					<DialogPanel className="m-auto w-full h-full max-w-4xl transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
						<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
							<div className="text-primary font-medium font-primary text-xl">Payout Settings</div>
							<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
						</div>
						<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
							<div className="flex flex-col gap-4">
								<div className="text-primary font-regular font-primary text-base">Successful referrals are paid using Wise. Please add the email address associated with your Wise account to be able to receive payments from Meco.</div>
								<div className="text-primary font-regular font-primary text-base">
									If you have any questions please email{" "}
									<a href="mailto:team@meco.app" target="_blank" className="text-success-green underline">
										team@meco.app
									</a>
									.
								</div>
							</div>
							<div className="flex flex-col gap-3">
								<div className="flex flex-col gap-4">
									<div>
										<Field className={"font-primary font-regular border border-primary-100 rounded-xl p-3 bg-surface-200"}>
											<Label className="text-base font-medium text-primary">Wise Email Address</Label>
											<Description className="text-sm leading-6 text-primary-500 mt-2">
												Please add the email address associated with your{" "}
												<a href="https://www.wise.com" className="font-bold underline text-primary" target="_blank">
													Wise account
												</a>{" "}
												below to be able to receive payments from Meco. If you don’t have a Wise account, you can create one now or provide your email address to receive a link to claim your funds through Wise when they are available.
											</Description>
											<Input type="email" disabled={isLoading} id="wise_email_address" autoFocus={false} value={payoutModel.wise_email_address} onChange={inputOnChange} className={"mt-3 block w-full rounded-[12px] bg-primary/5 py-1.5 px-3 text-sm/6 text-primary focus:outline-none focus:outline-2 focus:-outline-offset-2 focus:outline-primary-200 border border-primary-200"} placeholder="Your Wise email address" />
										</Field>
									</div>
									<div>
										<Field className={"font-primary font-regular border border-primary-100 rounded-xl p-3 bg-surface-200"}>
											<Label className="text-base font-medium text-primary">Wise Currency</Label>
											<Description className="text-sm leading-6 text-primary-500 mt-2">Add your local Wise currency. E.g. If you are a US resident, this should be USD. If you don't have a Wise account yet, select your local currency.</Description>
											<ListboxView selected={payoutModel.wise_currency === "" ? currencyPlaceholder : payoutModel.wise_currency} setSelected={(selectionString) => handleCurrencyChange(selectionString)} options={currenciesWithEmojis} className="!w-full mt-3" />
										</Field>
									</div>
									<div>
										<Field className={"font-primary font-regular border border-primary-100 rounded-xl p-3 bg-surface-200"}>
											<Label className="text-base font-medium text-primary">Wise Account Type</Label>
											<Description className="text-sm leading-6 text-primary-500 mt-2">Add your your account type.</Description>
											<ListboxView selected={payoutModel.wise_account_type === "" ? accountTypePlaceholder : payoutModel.wise_account_type} setSelected={(selectionString) => handleAccountTypeChange(selectionString)} options={accountTypes} className="!w-full mt-3" />
										</Field>
									</div>
								</div>
							</div>
						</div>
						<div className="absolute bottom-0 w-full bg-surface backdrop-blur-md border-t border-primary-100 px-4 py-4 pt-3 z-20">
							<div className="flex flex-row justify-end">
								<button type="submit" disabled={!isFormValid || !isValidEmail || isLoading} className={`text-white font-medium font-primary focus:outline-none rounded-xl text-base p-2 px-3 py-2 items-center bg-success-green whitespace-nowrap ${isValidEmail && isFormValid ? "opacity-100" : "opacity-50"}`} onClick={onSubmitClick}>
									{isLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" /> : <div>Save</div>}
								</button>
							</div>
						</div>
					</DialogPanel>
				</div>
			</Dialog>
		</>
	);
};

export default EarnPayoutSettingsModal;
