import { useState, useEffect } from "react";
import collageBalloon from "@images/collage_paywall_balloon.png";
import { ReactComponent as MecoLogo } from "@images/meco_logo.svg";
import { ReactComponent as RightArrow } from "@images/right_arrow.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import ParticlesBackground from "@components/common/ParticlesBackground";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { kStringConstants } from "@utils/constants/StringConstants";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MagicLinkObject } from "./MagicLinkView";
import * as userManager from "@utils/managers/UserManager";
import { ToastState, generateToast } from "@utils/managers/ToastManager";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const LoginView = () => {
	const [isValidEmail, setValidEmail] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [emailAddress, setEmailAddress] = useState<string | null>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const magicLinkObject: MagicLinkObject | null = location.state?.magicLinkObject;
	const sessionErrorObject: boolean | null = location.state?.hasSessionExpired || location.search.includes("expired=true");

	useEffect(() => {
		userManager.logOutUser();

		if (magicLinkObject) {
			const errorToastState: ToastState = {
				status: "error",
				title: "Something went wrong",
				message: "Please retry with a new magic link or contact us at team@meco.app if the issue persists.",
			};
			generateToast(errorToastState);
			setEmailAddress(magicLinkObject.email_address);
			validateEmailAddress(magicLinkObject.email_address);
			return;
		}

		if (sessionErrorObject) {
			const errorToastState: ToastState = {
				status: "error",
				title: "Your session has expired.",
				message: "Please log in again.",
			};
			generateToast(errorToastState);
			return;
		}
	}, []);

	const emailInputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const emailValue: string = e.currentTarget.value;
		setEmailAddress(emailValue);
		validateEmailAddress(emailValue);
	};

	const validateEmailAddress = (emailAddressString) => {
		const isValid = checkEmail(emailAddressString);
		setValidEmail(isValid);
	};

	const goToMagicLink = (emailAddress) => {
		navigate("/magic-link", { state: { email: emailAddress } });
	};

	const continueButtonOnClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!emailAddress || !isValidEmail) {
			return;
		}

		setLoading(true);

		networkManager
			.logInUser(emailAddress)
			.then((_response) => {
				recordEvent(kAnalyticsConstants.Onboarding.accountLoginSuccessful, { email: emailAddress });
				recordEvent(kAnalyticsConstants.Onboarding.magicLinkSent, { email: emailAddress, is_login: true });
				goToMagicLink(emailAddress);
			})
			.catch((error) => {
				setLoading(false);
				generateLogInError(error);
				recordEvent(kAnalyticsConstants.Onboarding.accountLoginError, { error: error.message, email: emailAddress });
			});
	};

	const generateLogInError = (error) => {
		var message = kStringConstants.Common.errorAlertMessage;

		if (error == kErrorConstants.logIn.nonExistantAccount) {
			message = "We couldn’t find an account with this email address.";
		}

		generateToast({ status: "error", message: message });
	};

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	const determineTitleText = () => {
		let textContent;

		if (magicLinkObject && !magicLinkObject.is_profile_setup) {
			textContent = "Resend magic link";
		} else {
			textContent = "Hello, again.";
		}

		return <div>{textContent}</div>;
	};

	const determineDescriptionText = () => {
		let textContent;

		if (magicLinkObject && !magicLinkObject.is_profile_setup) {
			textContent = "Enter your email below and we’ll email you a new magic link.";
		} else {
			textContent = "Enter the email that you signed up with and we’ll email you a magic link to log in.";
		}

		return <div>{textContent}</div>;
	};

	return (
		<div>
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="flex flex-col md:gap-0 gap-10 items-center justify-center p-3">
				<div className="text-white font-regular font-primary text-2xl"><span className="font-black text-3xl">Meco</span> for Publishers</div>
					<div className="meco_container text-center max-w-[500px] py-[40px] md:p-[60px]">
						<div className="flex flex-col gap-2">
							<div className="text-white font-black font-primary text-3xl">{determineTitleText()}</div>
							<div className="text-white/50 font-regular font-primary text-lg">{determineDescriptionText()}</div>
							<form className="flex flex-row  mt-4 gap-3" onSubmit={continueButtonOnClick}>
								<input type="email" value={emailAddress ?? ""} disabled={isLoading} autoFocus={true} onChange={emailInputOnChange} className={`flex-grow bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Enter email address" />
								<button type="submit" disabled={!isValidEmail || isLoading} className={`flex items-center justify-center focus:ring-2 focus:border-white focus:ring-white w-[55px] h-[55px] bg-success-green rounded-full font-regular font-primary text-lg ${isValidEmail ? "opacity-100" : "opacity-50"}`}>
									{isLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" /> : <RightArrow className="fill-white h-[20px]" />}
								</button>
							</form>

							<div className="text-white font-regular font-primary text-lg mt-5">
								Don't have an account?{" "}
								<Link to="/get-started" className="text-success-green hover:text-success-green transition ease-in-out">
									Sign up
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginView;
