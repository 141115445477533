import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { PUBBReferralOverviewStatsResponse, PUBReferralDetail } from "@models/Models";
import { useAuth } from "@providers/AuthContext";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { generateToast, ToastState } from "@utils/managers/ToastManager";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@tremor/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { DollarSign, Banknote, CircleDollarSign, Trophy, MousePointerClick, CircleCheck, Info } from "lucide-react";
import MetricIcon from "@components/common/MetricIcon";
import { MonthPicker } from "../../utils/MonthPicker/MonthPicker.jsx";
import { MonthInput } from "../../utils/MonthPicker/MonthInput.jsx";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import SectionLayout from "../../layouts/SectionLayout";
import Tooltip from "../../utils/Tooltip";
import TitleHeader from "@components/common/TitleHeader";
import { useLocation } from "react-router-dom";
import EarnPayoutSettingsModal from "./EarnPayoutSettingsModal";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import * as analyticsManager from "@utils/managers/AnalyticsManager";

const EarnDashboard = () => {
	const { activeNewsletter } = useAuth();
	const [isLoading, setLoading] = useState<boolean>(true);
	const [isStatsLoading, setStatsLoading] = useState<boolean>(true);
	const [selectedMonthData, setSelectedMonthData] = useState({ month: null, year: null, monthName: null, monthShortName: null, minDate: null as string | null });
	const [referralDetail, setReferralDetail] = useState<PUBReferralDetail | null>(null);
	const [overviewStatResponse, setOverviewStatResponse] = useState<PUBBReferralOverviewStatsResponse | null>(null);
	const [isMonthPickerOpen, setIsMonthPickerOpen] = useState(false);
	const pickerRef = useRef<HTMLDivElement>(null);
	const [isMetricLoading, setIsMetricLoading] = useState<boolean>(false);
	const location = useLocation();
	const [showPayoutSettingsModal, setPayoutSettingsModal] = useState(false);

	useEffect(() => {
		analyticsManager.recordEvent(kAnalyticsConstants.Earn.earnDashboardClicked);
		getData();
	}, []);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const isPayoutSettings = queryParams.get("payoutSettings") === "true";
		if (isPayoutSettings) {
			setPayoutSettingsModal(true);
		}
	}, [location.search]);

	useEffect(() => {
		if (isMonthPickerOpen) {
			document.addEventListener("mousedown", handlePickerClickOutside);
		} else {
			document.removeEventListener("mousedown", handlePickerClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handlePickerClickOutside);
		};
	}, [isMonthPickerOpen]);

	const handlePickerClickOutside = (event: MouseEvent) => {
		if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
			setIsMonthPickerOpen(false);
		}
	};

	const getData = () => {
		setLoading(true);
		networkManager
			.getReferralLink(activeNewsletter!.id.toString())
			.then((referralDetail) => {
				setReferralDetail(referralDetail);

				setSelectedMonthData({
					...selectedMonthData,
					minDate: referralDetail.active_from,
				});
				setLoading(false);

				return networkManager.getReferralOverviewStats(activeNewsletter!.id.toString(), selectedMonthData);
			})
			.then((referralOverviewResponse) => {
				setOverviewStatResponse(referralOverviewResponse);
				setStatsLoading(false);
			})
			.catch((_) => {
				setLoading(false);
				setStatsLoading(false);
				const errorToastState: ToastState = {
					status: "error",
					title: "Something went wrong",
					message: "We couldn't get your referral link. Please try again later.",
				};
				generateToast(errorToastState);
			});
	};

	const onPickerDateChange = (dateData) => {
		if (!activeNewsletter) {
			return;
		}

		if (dateData) {
			setSelectedMonthData({
				...dateData,
				minDate: selectedMonthData.minDate,
			});
			setIsMetricLoading(true);
			networkManager
				.getReferralOverviewStats(activeNewsletter.id.toString(), dateData)
				.then((referralOverviewResponse) => {
					setOverviewStatResponse(referralOverviewResponse);
					setIsMetricLoading(false);
				})
				.catch((_error) => {
					generateToast({ status: "error", title: "Something went wrong.", message: "We couldn't load your stats. Please try again later." });
				});
		}
	};

	const getCardIcon = (statId) => {
		switch (statId) {
			case "earnings":
				return Banknote;
			case "price":
				return CircleDollarSign;
			case "referrals":
				return Trophy;
			case "referral_traffic":
				return MousePointerClick;
			default:
				return CircleCheck;
		}
	};

	const copyToClipboard = () => {
		navigator.clipboard.writeText(referralDetail?.referral_url ?? "");
		const errorToastState: ToastState = {
			status: "success",
			message: "Referral link copied to clipboard.",
		};
		generateToast(errorToastState);
	};

	const assetsOnClick = () => {
		analyticsManager.recordEvent(kAnalyticsConstants.Earn.assetsViewed, { newsletter_id: activeNewsletter!.id.toString() });
		window.open("https://jeans-itch-5me.craft.me/xrPw4KLEJhw2MF", "_blank");
	};

	return (
		<>
			{isLoading ? (
				<div className="w-full h-[100vh] flex items-center justify-center">
					<LoadingSpinner className="w-[40px] text-primary-200 animate-spin fill-primary" />
				</div>
			) : (
				<>
					<EarnPayoutSettingsModal
						isShow={showPayoutSettingsModal}
						onUpdatePayoutDetails={() => {
							window.location.reload();
						}}
						onClose={() => {
							setPayoutSettingsModal(false);
						}}
					/>
					<TitleHeader title="Earn with Meco" />
					<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12 sm:min-w-[500px]">
						<div className="flex flex-col gap-5 m-auto">
							{referralDetail && referralDetail.is_paused && (
								<div className="w-full border-[1px] bg-red-600 border-card rounded-[10px] p-[20px] font-regular font-primary text-white text-base flex flex-row items-center gap-3">
									<Icon icon={ExclamationTriangleIcon} color="amber" variant="light" size="sm" />
									<div>
										Your access to the Meco Partner Program is currently on hold and under review by the Meco team. Please refrain from featuring Meco, as future referrals will not be counted at this time. If your application is approved, your referral link will be reactivated and displayed here. For more information, please{" "}
										<a href="mailto:team@meco.app" target="_blank" className="text-white">
											contact us
										</a>
										.
									</div>
								</div>
							)}
							{referralDetail && !activeNewsletter!.wise_email && (
								<div className="w-full border-[1px] bg-red-600 border-card rounded-[10px] p-[20px] font-regular font-primary text-white text-base flex flex-row items-center gap-3">
									<Icon icon={ExclamationTriangleIcon} color="amber" variant="light" size="sm" />
									<div>
										[Important] Referral payouts, previously processed through PayPal, will now be handled through Wise. Please update your details in the{" "}
										<button className="underline font-medium" onClick={() => setPayoutSettingsModal(true)}>
											Payout Settings
										</button>{" "}
										to ensure uninterrupted payouts. If you have any questions, feel free to{" "}
										<a href="mailto:team@meco.app" target="_blank" className="text-white">
											contact us
										</a>
										.
									</div>
								</div>
							)}
							{referralDetail && !referralDetail.is_paused && (
								<div className="container mx-auto">
									<div className="flex flex-col gap-5 m-auto">
										<div className="w-full border-[1px] border-primary-100 rounded-[10px] p-[20px] bg-surface-200">
											<div className="flex flex-wrap gap-4 md:gap-5 items-center justify-center">
												<div className="flex-1 flex flex-col gap-1 md:items-start items-center md:text-left text-center min-w-[200px]">
													<div className="text-primary font-medium font-primary text-lg">Your referral link</div>
													<div className="text-primary-500 font-regular font-primary text-sm">Use this link when promoting Meco in your newsletter and social channels. Please use the assets and guidelines provided when featuring Meco in your newsletter. Any successful referrals and earnings will be displayed below on your monthly dashboard.</div>
												</div>
												<div className="flex-none flex flex-col gap-3 items-center w-full sm:w-auto">
													<input value={referralDetail?.referral_url ?? ""} disabled={true} autoFocus={false} className="w-full sm:w-[300px] flex-grow bg-secondary focus:ring-1 focus:border-primary focus:ring-primary border border-primary-200 rounded-[12px] font-regular font-primary placeholder-primary-500" />
													<div className="flex flex-col sm:flex-row w-full justify-between gap-3">
														<button className="text-primary font-medium font-primary text-base focus:outline-none rounded-xl px-3 py-2 items-center border-solid border-[1px] border-primary-500 whitespace-nowrap" onClick={() => copyToClipboard()}>
															Copy referral link
														</button>
														<button className="text-white font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm px-3 py-2 items-center bg-success-green whitespace-nowrap" onClick={() => assetsOnClick()}>
															View assets
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{referralDetail && referralDetail.active_price && (
								<div className="w-full border-[1px] border-primary-100 rounded-[10px] p-[20px] bg-surface-200 font-regular font-primary text-base">
									<div className="flex flex-col sm:flex-row gap-4 sm:gap-3 items-start sm:items-center">
										<MetricIcon Icon={DollarSign} />
										<div>Your current payout per referral is {referralDetail?.active_price}</div>
									</div>
								</div>
							)}

							<SectionLayout
								id="stats"
								sectionTitle="Your Monthly Stats"
								sectionRightDiv={
									<div>
										<MonthInput size="small" selected={selectedMonthData} setShowMonthPicker={setIsMonthPickerOpen} showMonthPicker={isMonthPickerOpen} />
										{isMonthPickerOpen ? <MonthPicker pickerRef={pickerRef} setIsOpen={setIsMonthPickerOpen} selected={selectedMonthData} onChange={(dateData) => onPickerDateChange(dateData)} /> : null}
									</div>
								}>
								{isStatsLoading ? (
									<div className="flex flex-row items-center justify-center"><LoadingSpinner className="w-[30px] text-primary-200 animate-spin fill-primary my-3" /></div>
								) : (
									<div className="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
										{overviewStatResponse &&
											overviewStatResponse.overview_stats
												.sort((a, b) => a.sort_index - b.sort_index)
												.map((stat) => {
													return (
														<div className="relative flex flex-col border-primary-100 bg-surface border duration-300 transition ease-in-out w-full item rounded-[15px] p-3 gap-3 min-w-[200px] h-[168px]">
															<div className="flex flex-row items-start justify-between">
																<div>
																	<MetricIcon Icon={getCardIcon(stat.id)} />
																	<div className="truncate mt-4">
																		<p className="font-primary font-regular text-lg text-primary">{stat.title}</p>
																		{isMetricLoading ? <LoadingSpinner className="w-[20px] text-primary-200 animate-spin fill-primary mt-2" /> : <p className="font-primary font-regular text-3xl text-primary">{stat.value}</p>}
																	</div>
																</div>
																<div className="absolute right-3 top-3">
																	{stat.hint && (
																		<Tooltip message={stat.hint}>
																			<Info size={24} />
																		</Tooltip>
																	)}
																</div>
															</div>
														</div>
													);
												})}
									</div>
								)}
							</SectionLayout>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default EarnDashboard;
