import React, { useState, useEffect, useRef } from "react";
import { ChevronDown } from "lucide-react";

interface NewsletterSwitcherProps {
	imageUrl?: string | undefined;
	options: string[];
	defaultValue?: string;
	setSelectedOption: (option: string | null) => void; // Setter function prop
}

const NewsletterSwitcher: React.FC<NewsletterSwitcherProps> = ({ imageUrl, options, defaultValue, setSelectedOption }) => {
	const [selectedOption, setSelected] = useState<string | undefined>(defaultValue);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const isMultipleOptions = options.length > 1;

	useEffect(() => {
		setSelected(defaultValue);
	}, [defaultValue]);

	const handleOptionClick = (option: string) => {
		setSelected(option);
		setSelectedOption(option); // Call the setter function with the selected option
		setIsOpen(false);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="relative" ref={dropdownRef}>
			{isOpen && (
				<div className="absolute bottom-[50px] z-20 w-full min-w-[160px] py-1 origin-top-left bg-secondary rounded-lg shadow-lg border border-primary-200">
					<div className="flex flex-col">
						{options.map((option, index) => (
							<div key={index} onClick={() => handleOptionClick(option)} className={`group px-2 py-2 mx-1 my-1 cursor-pointer rounded-lg hover:bg-success-green`}>
								<div className="font-primary font-regular text-base group-hover:text-white">{option}</div>
							</div>
						))}
					</div>
				</div>
			)}

			<button disabled={!isMultipleOptions} onClick={() => setIsOpen(!isOpen)} className={`focus:outline-none border-solid border-primary-100 rounded-lg text-sm px-2.5 py-2.5 inline-flex gap-1 w-full justify-center items-center ${isOpen ? "bg-success-green/20" : "bg-transparent"} ${isMultipleOptions ? "hover:bg-primary-200" : ""} text-brand-blue transition ease-in-out duration-300`}>
				<div className="flex flex-row gap-2 items-center">
					{imageUrl && <img src={imageUrl} className="h-[20px] aspect-square rounded-full" />}
					<div className="font-primary font-medium text-base text-primary">{selectedOption}</div>
				</div>
				{isMultipleOptions && <ChevronDown className="stroke-primary h-[20px] w-[20px] text-primary-500 mt-1" />}
			</button>
		</div>
	);
};

export default NewsletterSwitcher;
